import {
  GET_ALL_STAFF_PATIENT_APPOINTMENTS,
  GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS,
  STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION,
} from '../../actions';

export const setStaffPatientAppointmentPagination = (payload) => {
  return {
    type: STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION,
    payload,
  };
};

export const AllStaffPatientsAppointments = (payload) => {
  return {
    type: GET_ALL_STAFF_PATIENT_APPOINTMENTS,
    payload,
  };
};

export const AllStaffPatientAppointmentSuccess = (payload) => {
  return {
    type: GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS,
    payload,
  };
};