/* eslint-disable import/prefer-default-export */
import {
  SET_LOADER,
  SET_TABLE_DATA_LOADER,
  SET_ACCOUNT_ACTIVATE_LOADER,
  SET_ACCOUNT_DEACTIVATE_LOADER,
  SET_VERIFY_LOADER,
  SET_DECLINE_LOADER,
  ACCOUNT_ACTIVATE,
  ACCOUNT_DEACTIVATE,
  SET_ACCOUNT_STATUS_RESPONSE,
  SET_MAP_STATE,
  SET_WORKPLACE_DATA,
  REGISTRATION_COUNCIL_DATA,
  REGISTRATION_COUNCIL_DATA_SUCCESS,
  MEDICAL_CONVENTION_DATA,
  MEDICAL_CONVENTION_DATA_SUCCESS,
  SEND_PASSWORD_LINK,
  IS_REDIRECT,
  UPLOAD_WORKPLACE_DOCUMENT,
  GET_WORKPLACE_DOCUMENT,
  GET_WORKPLACE_DOCUMENT_SUCCESS,
  UPLOAD_WORKPLACE_DOCUMENT_SUCCESS,
  SET_SUBMIT_LOADER,
  SET_LIST_LOADER,
  SET_TAB,
  SET_MASTER_CURRENT_PAGE,
  SET_CURRENT_ROUTE,
  SET_ORDER_DETAILS_LOADER,
} from '../actions';

export const setLoader = (payload) => {
  return {
    type: SET_LOADER,
    payload,
  };
};

export const setListLoader = (payload) => {
  return {
    type: SET_LIST_LOADER,
    payload,
  };
};

export const setTableDataLoader = (payload) => {
  return {
    type: SET_TABLE_DATA_LOADER,
    payload,
  };
};

export const setOrderLoader = (payload) => {
  return {
    type: SET_ORDER_DETAILS_LOADER,
    payload,
  };
};

export const setSubmitLoader = (payload) => {
  return {
    type: SET_SUBMIT_LOADER,
    payload,
  };
};

export const setAccountActivateLoader = (payload) => {
  return {
    type: SET_ACCOUNT_ACTIVATE_LOADER,
    payload,
  };
};
export const setAccountDeactivateLoader = (payload) => {
  return {
    type: SET_ACCOUNT_DEACTIVATE_LOADER,
    payload,
  };
};
export const setVerifyLoader = (payload) => {
  return {
    type: SET_VERIFY_LOADER,
    payload,
  };
};
export const setDeclineLoader = (payload) => {
  return {
    type: SET_DECLINE_LOADER,
    payload,
  };
};

export const accountDeactivate = (payload) => {
  return {
    type: ACCOUNT_DEACTIVATE,
    payload,
  };
};

export const accountActivate = (payload) => {
  return {
    type: ACCOUNT_ACTIVATE,
    payload,
  };
};

export const setAccountStatusResponse = (payload) => {
  return {
    type: SET_ACCOUNT_STATUS_RESPONSE,
    payload,
  };
};

export const setMapState = (name, value) => {
  return {
    type: SET_MAP_STATE,
    payload: { name, value },
  };
};

export const setWorkplaceListData = (payload) => {
  return {
    type: SET_WORKPLACE_DATA,
    payload,
  };
};

export const getRegistrationCouncil = (payload) => {
  return {
    type: REGISTRATION_COUNCIL_DATA,
    payload,
  };
};

export const getRegistrationCouncilSuccess = (payload) => {
  return {
    type: REGISTRATION_COUNCIL_DATA_SUCCESS,
    payload,
  };
};

export const getMedicalConvention = (payload) => {
  return {
    type: MEDICAL_CONVENTION_DATA,
    payload,
  };
};

export const getMedicalConventionSuccess = (payload) => {
  return {
    type: MEDICAL_CONVENTION_DATA_SUCCESS,
    payload,
  };
};

export const sendPasswordLink = (payload) => {
  return {
    type: SEND_PASSWORD_LINK,
    payload,
  };
};

export const setIsRedirect = (payload) => {
  return {
    type: IS_REDIRECT,
    payload,
  };
};

export const uploadWorkplaceDocument = (payload) => {
  return {
    type: UPLOAD_WORKPLACE_DOCUMENT,
    payload,
  };
};

export const uploadWorkplaceDocumentSuccess = (payload) => {
  return {
    type: UPLOAD_WORKPLACE_DOCUMENT_SUCCESS,
    payload,
  };
};

export const getWorkplaceDocument = (payload) => {
  return {
    type: GET_WORKPLACE_DOCUMENT,
    payload,
  };
};

export const setWorkplaceDocumentSuccess = (payload) => {
  return {
    type: GET_WORKPLACE_DOCUMENT_SUCCESS,
    payload,
  };
};

export const setTab = (payload) => {
  return {
    type: SET_TAB,
    payload,
  };
};

export const setMasterCurrentPage = (payload) => {
  return {
    type: SET_MASTER_CURRENT_PAGE,
    payload,
  };
};

export const setCurrentRoute = (payload) => {
  return {
    type: SET_CURRENT_ROUTE,
    payload,
  };
};
