import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_DRUG_HISTORY,
  GET_MERGED_DRUG,
  SAVE_DRUG,
  GET_DRUG_DETAILS_BY_ID,
  SAVE_MERGE_DRUG,
  GET_PHARMACIES_DRUG,
} from '../../actions';
import {
  DRUG_HISTORY_EP,
  MERGED_DRUG_EP,
  SAVE_DRUG_EP,
  DRUG_INFO_BY_ID_EP,
  SAVE_MERGE_DRUG_EP,
  GET_PHARMACY_DRUG_EP,
} from '../../../constants/apiEndPoints';

import {
  getDrugHistorySuccess,
  getMergedDrugSuccess,
  saveDrugSuccess,
  getDrugDetailsByIdSuccess,
  saveMergeDrugSuccess,
  getPharmaciesDrugSuccess,
} from './actions';
import {
  setLoader,
  setListLoader,
  setSubmitLoader,
} from '../../common/actions';
import { alertDialog } from '../../../helpers/commonHelper';

const getDrugHistoryListAsync = async (item) => {
  let data = null;
  const apiUrl = `${DRUG_HISTORY_EP}/${item.payload.drug_id}?limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl);
  }
  return data;
};

const getMergedDrugListAsync = async (item) => {
  let data = null;
  const apiUrl = `${MERGED_DRUG_EP}/${item.payload.drug_id}?limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl);
  }
  return data;
};

const setSaveDrugAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', SAVE_DRUG_EP, item.payload);
  return data;
};

const getDrugInfoByIdAsync = async (item) => {
  const apiUrl = `${DRUG_INFO_BY_ID_EP}/${item.payload.drug_id}`;

  const data = AXIOS_AUTH_KIT('GET', apiUrl);
  return data;
};

const setSaveMergeDrugAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', SAVE_MERGE_DRUG_EP, item.payload);
  return data;
};

const getPharmaciesDrugListAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('GET', GET_PHARMACY_DRUG_EP + item.payload);
  return data;
};

function* getDrugHistoryList(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(getDrugHistoryListAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getDrugHistorySuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getMergedDrugList(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getMergedDrugListAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getMergedDrugSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setSaveDrug(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setSaveDrugAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(saveDrugSuccess(res.data.data));
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(logisnUserError(error));
  }
}

function* getDrugInfoById(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(getDrugInfoByIdAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getDrugDetailsByIdSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setSaveMergeDrug(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setSaveMergeDrugAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(saveMergeDrugSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getPharmaciesDrugList(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getPharmaciesDrugListAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getPharmaciesDrugSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchDrugHistoryList() {
  yield takeLatest(GET_DRUG_HISTORY, getDrugHistoryList);
}

export function* watchMergedDrugList() {
  yield takeLatest(GET_MERGED_DRUG, getMergedDrugList);
}

export function* watchSaveDrug() {
  yield takeLatest(SAVE_DRUG, setSaveDrug);
}

export function* watchDrugInfoById() {
  yield takeLatest(GET_DRUG_DETAILS_BY_ID, getDrugInfoById);
}

export function* watchSaveMergeDrug() {
  yield takeLatest(SAVE_MERGE_DRUG, setSaveMergeDrug);
}

export function* watchPharmaciesDrugList() {
  yield takeLatest(GET_PHARMACIES_DRUG, getPharmaciesDrugList);
}

export default function* rootSaga() {
  yield all([fork(watchDrugHistoryList)]);
  yield all([fork(watchMergedDrugList)]);
  yield all([fork(watchSaveDrug)]);
  yield all([fork(watchDrugInfoById)]);
  yield all([fork(watchSaveMergeDrug)]);
  yield all([fork(watchPharmaciesDrugList)]);
}
