import {
  PENDING_ACTION_PAGINATION,
  GET_PENDING_LIST,
  GET_PENDING_LIST_SUCCESS,
} from '../actions';

const INIT_STATE = {
  pendingActionPagination: [],
  allPendingList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PENDING_ACTION_PAGINATION:
      return { ...state, pendingActionPagination: action.payload };

    case GET_PENDING_LIST:
      return { ...state, loading: true, error: '' };

    case GET_PENDING_LIST_SUCCESS:
      return { ...state, allPendingList: action.payload };

    default:
      return { ...state };
  }
};
