import {
  SET_DRUG_PAGINATION,
  GET_DRUG_HISTORY,
  GET_DRUG_HISTORY_SUCCESS,
  GET_MERGED_DRUG,
  GET_MERGED_DRUG_SUCCESS,
  SAVE_DRUG,
  SAVE_DRUG_SUCCESS,
  GET_DRUG_DETAILS_BY_ID,
  GET_DRUG_DETAILS_BY_ID_SUCCESS,
  SAVE_MERGE_DRUG,
  SAVE_MERGE_DRUG_SUCCESS,
  GET_PHARMACIES_DRUG,
  GET_PHARMACIES_DRUG_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  drugPagination: [],
  drugHistoryList: null,
  mergedDrugList: null,
  saveDrugSucces: null,
  drugListById: null,
  saveMergeDrugSuccessData: null,
  pharmaciesDrugList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DRUG_PAGINATION:
      return { ...state, drugPagination: action.payload };

    case GET_DRUG_HISTORY:
      return { ...state, loading: true, error: '' };

    case GET_DRUG_HISTORY_SUCCESS:
      return { ...state, drugHistoryList: action.payload };

    case GET_MERGED_DRUG:
      return { ...state, loading: true, error: '' };

    case GET_MERGED_DRUG_SUCCESS:
      return { ...state, mergedDrugList: action.payload };

    case SAVE_DRUG:
      return { ...state, loading: true, error: '' };

    case SAVE_DRUG_SUCCESS:
      return { ...state, saveDrugSucces: action.payload };

    case GET_DRUG_DETAILS_BY_ID:
      return { ...state, loading: true, error: '' };

    case GET_DRUG_DETAILS_BY_ID_SUCCESS:
      return { ...state, drugListById: action.payload };

    case SAVE_MERGE_DRUG:
      return { ...state, loading: true, error: '' };

    case SAVE_MERGE_DRUG_SUCCESS:
      return { ...state, saveMergeDrugSuccessData: action.payload };

    case GET_PHARMACIES_DRUG:
      return { ...state, loading: true, error: '' };

    case GET_PHARMACIES_DRUG_SUCCESS:
      return { ...state, pharmaciesDrugList: action.payload };

    default:
      return { ...state };
  }
};
