import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import doctorSagas from './userManagement/doctor/saga';
import patientSagas from './userManagement/patient/saga';
import staffSagas from './userManagement/staff/saga';
import laboratorySagas from './userManagement/laboratory/saga';
import pharmacistSagas from './userManagement/pharmacist/saga';
import dashboardSagas from './dashboard/saga';
import commonSagas from './common/saga';
import role from './roleManagament/saga';
import notification from './notification/saga';
import app from './appManagement/saga';
import addressesSagas from './appManagement/addresses/saga';
import specialitiesSagas from './appManagement/specialities/saga';
import drugInformation from './appManagement/drugInformation/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    doctorSagas(),
    patientSagas(),
    staffSagas(),
    laboratorySagas(),
    pharmacistSagas(),
    dashboardSagas(),
    commonSagas(),
    role(),
    notification(),
    app(),
    addressesSagas(),
    specialitiesSagas(),
    drugInformation(),
  ]);
}
