import {
  all,
  call,
  fork,
  put,
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../helpers/apiClient';
import {
  ACCOUNT_ACTIVATE,
  ACCOUNT_DEACTIVATE,
  REGISTRATION_COUNCIL_DATA,
  SEND_PASSWORD_LINK,
  UPLOAD_WORKPLACE_DOCUMENT,
  GET_WORKPLACE_DOCUMENT,
} from '../actions';
import {
  CHANGE_ACCOUNT_STATUS_EP,
  GET_LIBRARY_EP,
  FORGET_PASSWORD_EP,
  UPLOAD_WORKPLACE_DOCUMENT_EP,
  GET_WORKPLACE_DOCUMENT_EP,
} from '../../constants/apiEndPoints';
import {
  setLoader,
  setTableDataLoader,
  setSubmitLoader,
  getRegistrationCouncilSuccess,
  getMedicalConventionSuccess,
  setAccountStatusResponse,
  setWorkplaceDocumentSuccess,
  uploadWorkplaceDocumentSuccess,
} from './actions';
import { alertDialog } from '../../helpers/commonHelper';
import ErrorMessage from '../../constants/errorMessage';

const accountActivateAsync = async (item) => {
  const data = {
    user_role_id: item.payload.user_role_id,
    active_status: 1,
  };
  const res = await AXIOS_AUTH_KIT('POST', CHANGE_ACCOUNT_STATUS_EP, data);

  return res;
};

const accountDeactivateAsync = async (item) => {
  const data = {
    user_role_id: item.payload.user_role_id,
    active_status: 0,
  };
  const res = await AXIOS_AUTH_KIT('POST', CHANGE_ACCOUNT_STATUS_EP, data);

  return res;
};

const registrationCouncilAsync = async () => {
  const res = await AXIOS_AUTH_KIT(
    'GET',
    `${GET_LIBRARY_EP}registration_council`
  );
  if (res.data.success) {
    const { data } = res.data;
    const newArry = [];
    for (let i = 0; i < data.length; i++) {
      const newObj = {
        value: data[i].id,
        label: data[i].name,
      };
      newArry.push(newObj);
    }

    return newArry;
  }
};

const medicalConventionAsync = async () => {
  const res = await AXIOS_AUTH_KIT(
    'GET',
    `${GET_LIBRARY_EP}medical_convention`
  );
  if (res.data.success) {
    const { data } = res.data;
    const newArry = [];
    for (let i = 0; i < data.length; i++) {
      const newObj = {
        value: data[i].id,
        label: data[i].name,
      };
      newArry.push(newObj);
    }

    return newArry;
  }
};

const sendPasswordLinkAsync = async (item) => {
  const data = {
    email: item.payload.email,
    contact_number: item.payload.contact_number,
  };
  const res = await AXIOS_AUTH_KIT('POST', FORGET_PASSWORD_EP, data);
  return res;
};

const uploadWorkplaceContactInfoAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT(
    'POST',
    UPLOAD_WORKPLACE_DOCUMENT_EP,
    item.payload
  );
  return res;
};

const getWorkplaceContactInfoAsync = async (item) => {
  const data = `workplace_id=${item.payload.workplace_id}&role_id=${item.payload.role_id}`;
  const res = await AXIOS_AUTH_KIT('GET', GET_WORKPLACE_DOCUMENT_EP + data);
  return res;
};

function* accountActivated(payload) {
  try {
    yield put(setLoader(true));
    const resData = yield call(accountActivateAsync, payload);
    if (resData.data.success) {
      yield put(setLoader(false));
      yield put(setAccountStatusResponse(resData.data));
      alertDialog('success', ErrorMessage.account_act_msg);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* accountDeactivated(payload) {
  try {
    yield put(setLoader(true));
    const resData = yield call(accountDeactivateAsync, payload);
    if (resData.data.success) {
      yield put(setLoader(false));
      yield put(setAccountStatusResponse(resData.data));
      alertDialog('success', ErrorMessage.account_deact_msg);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* registrationCouncil(payload) {
  try {
    const resData = yield call(registrationCouncilAsync, payload);
    if (resData) {
      yield put(getRegistrationCouncilSuccess(resData));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* medicalConvention(payload) {
  try {
    const resData = yield call(medicalConventionAsync, payload);
    if (resData) {
      yield put(getMedicalConventionSuccess(resData));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* sendPasswordLink(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(sendPasswordLinkAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      alertDialog('success', res.data.data);
    } else if (res.status === 400) {
      if (res.data[0].constraints !== '') {
        alertDialog('error', res.data[0].constraints);
      } else {
        alertDialog('error', res.data[0].constraints.isMobilePhone);
      }
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* uploadWorkplaceContactInfo(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(uploadWorkplaceContactInfoAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      alertDialog('success', ErrorMessage.workplace_success_msg);
      yield put(uploadWorkplaceDocumentSuccess(res.data));
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getWorkplaceContactInfo(payload) {
  try {
    yield put(setTableDataLoader(true));
    const res = yield call(getWorkplaceContactInfoAsync, payload);
    yield put(setTableDataLoader(false));
    if (res.data.success) {
      yield put(setWorkplaceDocumentSuccess(res.data));
    } else if (res.status === 400) {
      if (res.data[0].constraints !== '') {
        alertDialog('error', res.data[0].constraints);
      } else {
        alertDialog('error', res.data[0].constraints.isMobilePhone);
      }
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchAccountActivate() {
  yield takeLatest(ACCOUNT_ACTIVATE, accountActivated);
}

export function* watchAccountDeactivate() {
  yield takeLatest(ACCOUNT_DEACTIVATE, accountDeactivated);
}

export function* watchRegistrationCouncil() {
  yield takeLatest(REGISTRATION_COUNCIL_DATA, registrationCouncil);
}

export function* watchMedicalConvention() {
  yield takeLatest(REGISTRATION_COUNCIL_DATA, medicalConvention);
}

export function* watchSendPasswordLink() {
  yield takeLatest(SEND_PASSWORD_LINK, sendPasswordLink);
}

export function* watchUploadWorkplaceDocument() {
  yield takeLatest(UPLOAD_WORKPLACE_DOCUMENT, uploadWorkplaceContactInfo);
}

export function* watchGetWorkplaceDocument() {
  yield takeLatest(GET_WORKPLACE_DOCUMENT, getWorkplaceContactInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchAccountActivate),
    fork(watchAccountDeactivate),
    fork(watchRegistrationCouncil),
    fork(watchMedicalConvention),
    fork(watchSendPasswordLink),
    fork(watchUploadWorkplaceDocument),
    fork(watchGetWorkplaceDocument),
  ]);
}
