import {
  all,
  call,
  fork,
  put,
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_ALL_PHARMACIST,
  GET_ALL_PHARMACIES,
  ADD_PHARMACIEST_USER,
  GET_PHARMACIST_PROFILE_DETAILS_BY_ID,
  VERIFY_DECLINE_PHARMACY,
  UPDATE_DELIVERY_DISCOUNT_INFO,
  GET_PHARMACY_ORDERS,
  GET_PHARMACY_ORDER_DETAILS,
  GET_PHARMACY_NO_OF_USERS,
  GET_PHARMACIES_DETAILS,
} from '../../actions';
import {
  GET_ALL_PHARMACIST_USER_EP,
  GET_ALL_PHARMACIES_EP,
  ADD_PHARMACY_USER_EP,
  PHARMACY_DETAAILS_BY_ID_EP,
  VERIFY_DECLINE_PHARMACY_EP,
  UPDATE_DELIVERY_INFO_EP,
  GET_PHARMACY_ORDERS_EP,
  GET_PHARMACY_ORDER_DETAILS_EP,
  GET_PHARMACY_USERS_EP,
  GET_PHARMACY_DETAILS_EP,
} from '../../../constants/apiEndPoints';
import {
  getAllPharmacistSuccess,
  getAllPharmaciesSuccess,
  getPharmacistDetailsByIdSuccess,
  setVerifyDeclinePharmacySuccess,
  updateDeliveryDiscountInfoSuccess,
  getPharmacyOrdersSuccess,
  getPharmacyOrderDetailsSuccess,
  getPharmacyNoOfUsersSuccess,
  getAllPharmaciesDetailsSuccess,
  addPharmacistUserSuccess,
} from './actions';
import {
  setLoader,
  setTableDataLoader,
  setIsRedirect,
  setListLoader,
  setSubmitLoader,
  setOrderLoader,
  setVerifyLoader,
  setDeclineLoader,
} from '../../common/actions';
import { alertDialog } from '../../../helpers/commonHelper';
import ErrorMessage from '../../../constants/errorMessage';

const getPharmacistAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.type !== null ? `&type=${item.payload.type}` : '';

  const apiUrl = `${GET_ALL_PHARMACIST_USER_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${apiUrl + searchStr + typeStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const pharmacyOrdersAsync = async (item) => {
  let data = null;
  let apiUrl = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  if (item.payload.userId !== undefined && item.payload.userId !== null) {
    apiUrl = `${GET_PHARMACY_ORDERS_EP}patient_id=${item.payload.userId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;
  }

  if (
    item.payload.pharmacyUserId !== undefined &&
    item.payload.pharmacyUserId !== null
  ) {
    apiUrl = `${GET_PHARMACY_ORDERS_EP}pharmacy_user_id=${item.payload.pharmacyUserId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;
  }

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${apiUrl + searchStr + typeStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const pharmacyOrderDetailsAsync = async (item) => {
  const apiUrl = `order_id=${item.payload.order_id}&pharmacy_user_id=${item.payload.pharmacy_user_id}&user_id=${item.payload.user_id}&prescription_type=${item.payload.prescription_type}&inprocess=${item.payload.inprocess}`;
  const data = await AXIOS_AUTH_KIT(
    'GET',
    GET_PHARMACY_ORDER_DETAILS_EP + apiUrl
  )
    .then((res) => res)
    .catch((errors) => errors);
  return data;
};

const pharmacyNoOFUsersAsync = async (item) => {
  let data = null;
  const apiUrl = `workplace_id=${item.payload.workplace_id}&offset=${item.payload.offset}&limit=${item.payload.limit}`;
  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${GET_PHARMACY_USERS_EP + apiUrl}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${GET_PHARMACY_USERS_EP + apiUrl}`);
  }

  return data;
};

const getPharmaciesAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  const apiUrl = `${GET_ALL_PHARMACIES_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${apiUrl + searchStr + typeStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const getPharmacyDetailsAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT(
    'GET',
    GET_PHARMACY_DETAILS_EP + item.payload
  );
  return data;
};

const addPharmacistUserAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('POST', ADD_PHARMACY_USER_EP, item.payload);
  return data;
};

const getPharmacistDetailsByIDAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT(
    'GET',
    `${PHARMACY_DETAAILS_BY_ID_EP}${item.payload.pharmacistDetailsId}`
  );
  return data;
};

const verifyDeclinePharmacyAsync = async (item) => {
  const data = {
    workplace_id: item.payload.workplace_id,
    isVerified: item.payload.isVerified,
  };
  const res = await AXIOS_AUTH_KIT('POST', VERIFY_DECLINE_PHARMACY_EP, data);

  return res;
};

const updateDeliveryAndDiscountInfoAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT(
    'POST',
    UPDATE_DELIVERY_INFO_EP,
    item.payload
  );

  return res;
};

function* getPharmacist(payload) {
  try {
    yield put(setLoader(true));
    const patientAppointmentList = yield call(getPharmacistAsync, payload);
    if (patientAppointmentList.data.success) {
      yield put(setLoader(false));
      yield put(getAllPharmacistSuccess(patientAppointmentList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getPharmacies(payload) {
  try {
    yield put(setLoader(true));
    const patientAppointmentList = yield call(getPharmaciesAsync, payload);
    if (patientAppointmentList.data.success) {
      yield put(setLoader(false));
      yield put(getAllPharmaciesSuccess(patientAppointmentList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* addPharmacistUser(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(addPharmacistUserAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      alertDialog('success', ErrorMessage.pharmacy_user_add_msg);
      yield put(setIsRedirect(true));
    }
    if (res.data.status === false) {
      if (res.data.message.includes('user_id')) {
        yield put(addPharmacistUserSuccess(res.data.message));
      } else {
        alertDialog('error', res.data.message);
      }
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getPharmacistDetailsByID(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getPharmacistDetailsByIDAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getPharmacistDetailsByIdSuccess(res.data.data));
    } else {
      yield put(getPharmacistDetailsByIdSuccess(null));
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* verifyDeclinePharmacy(payload) {
  try {
    if (payload.payload.isVerified === 1) {
      yield put(setVerifyLoader(true));
    } else {
      yield put(setDeclineLoader(true));
    }

    const resData = yield call(verifyDeclinePharmacyAsync, payload);
    if (payload.payload.isVerified === 1) {
      yield put(setVerifyLoader(false));
    } else {
      yield put(setDeclineLoader(false));
    }
    if (resData.data.success) {
      yield put(setVerifyDeclinePharmacySuccess(resData.data));
      if (payload.payload.isVerified === 1) {
        alertDialog('success', ErrorMessage.pharmacy_verify_msg);
      } else {
        alertDialog('success', ErrorMessage.pharmacy_decline_msg);
      }
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* updateDeliveryAndDiscountInfo(payload) {
  try {
    yield put(setSubmitLoader(true));
    const resData = yield call(updateDeliveryAndDiscountInfoAsync, payload);
    yield put(setSubmitLoader(false));
    if (resData.data.success) {
      yield put(updateDeliveryDiscountInfoSuccess(resData.data));
      alertDialog(
        'success',
        ErrorMessage.pharmacy_delivery_discount_update_msg
      );
    } else if (resData.data.status === false) {
      alertDialog('error', 'Please enter only numbers');
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* pharmacyOrders(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(pharmacyOrdersAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getPharmacyOrdersSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* pharmacyOrderDetails(payload) {
  try {
    yield put(setOrderLoader(true));
    const res = yield call(pharmacyOrderDetailsAsync, payload);
    yield put(setOrderLoader(false));
    if (res.data.success) {
      yield put(getPharmacyOrderDetailsSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* pharmacyNoOFUsers(payload) {
  try {
    // yield put(setLoader(true));
    const res = yield call(pharmacyNoOFUsersAsync, payload);
    // if (res.data.success) {
    // yield put(setLoader(false));
    yield put(getPharmacyNoOfUsersSuccess(res.data.data));
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getPharmacyDetails(payload) {
  try {
    yield put(setTableDataLoader(true));
    const res = yield call(getPharmacyDetailsAsync, payload);
    yield put(setTableDataLoader(false));
    if (res.data.success) {
      yield put(getAllPharmaciesDetailsSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchPharmacist() {
  yield takeLatest(GET_ALL_PHARMACIST, getPharmacist);
}

export function* watchPharmacies() {
  yield takeLatest(GET_ALL_PHARMACIES, getPharmacies);
}

export function* watchAddPharmacistUser() {
  yield takeLatest(ADD_PHARMACIEST_USER, addPharmacistUser);
}

export function* watchPharmacistDetailsById() {
  yield takeLatest(
    GET_PHARMACIST_PROFILE_DETAILS_BY_ID,
    getPharmacistDetailsByID
  );
}

export function* watchVerifyDeclinePharmacy() {
  yield takeLatest(VERIFY_DECLINE_PHARMACY, verifyDeclinePharmacy);
}

export function* watchUpdateDeliveryDiscountInfo() {
  yield takeLatest(
    UPDATE_DELIVERY_DISCOUNT_INFO,
    updateDeliveryAndDiscountInfo
  );
}

export function* watchPharmacyOrders() {
  yield takeLatest(GET_PHARMACY_ORDERS, pharmacyOrders);
}

export function* watchPharmacyOrderDetails() {
  yield takeLatest(GET_PHARMACY_ORDER_DETAILS, pharmacyOrderDetails);
}

export function* watchPharmacyNoOFUsers() {
  yield takeLatest(GET_PHARMACY_NO_OF_USERS, pharmacyNoOFUsers);
}

export function* watchPharmaciesDetails() {
  yield takeLatest(GET_PHARMACIES_DETAILS, getPharmacyDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchPharmacist),
    fork(watchPharmacies),
    fork(watchAddPharmacistUser),
    fork(watchPharmacistDetailsById),
    fork(watchVerifyDeclinePharmacy),
    fork(watchUpdateDeliveryDiscountInfo),
    fork(watchPharmacyOrders),
    fork(watchPharmacyOrderDetails),
    fork(watchPharmacyNoOFUsers),
    fork(watchPharmaciesDetails),
  ]);
}
