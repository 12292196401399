import {
  all,
  call,
  fork,
  put,
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_ALL_LAB_USER,
  GET_ALL_LABORATORIES,
  ADD_LAB_USER,
  GET_LAB_USER_PROFILE_DETAILS_BY_ID,
  SET_VERIFY_LABORATORY,
  SET_DECLINE_LABORATORY,
  GET_LAB_ORDERS,
  GET_LAB_ORDER_DETAILS,
  UPDATE_REPORT_HOME_COLLECTION,
  GET_LAB_NO_OF_USERS,
  GET_ALL_LAB_BY_WORKPLACE,
  GET_ALL_FRANCHISE,
  GET_LAB_TEST_DATA,
  GET_EXISTING_LAB_TEST_DATA,
  ADD_LABORATORY,
  GET_LABORATORY_DETAILS,
} from '../../actions';
import {
  GET_ALL_LAB_USER_EP,
  GET_ALL_LABORATORIES_EP,
  ADD_LAB_USER_EP,
  LAB_USER_DETAILS_BY_ID_EP,
  VERIFY_LABORATORY_EP,
  GET_LAB_ORDERS_EP,
  GET_LAB_ORDER_DETAILS_EP,
  UPDATE_REPORT_HOME_COLLECTION_EP,
  GET_LAB_USERS_EP,
  GET_ALL_LABS_BY_WORKPLACE_EP,
  GET_LIBRARY_EP,
  GET_EXISTING_LAB_TESTS_EP,
  ADD_LABORATORY_EP,
  GET_LAB_DETAILS_BY_ID_EP,
} from '../../../constants/apiEndPoints';
import {
  setLoader,
  setSubmitLoader,
  setListLoader,
  setIsRedirect,
  setVerifyLoader,
  setDeclineLoader,
  setTableDataLoader,
  setOrderLoader,
} from '../../common/actions';

import {
  getAllLabUserSuccess,
  getAllLaboratoriesSuccess,
  setAddLabDetails,
  getLabUserDetailsByIdSuccess,
  setVerifyDeclineLaboratorySuccess,
  getLabOrdersSuccess,
  getLabOrderDetailsSuccess,
  updateHomeReportCollectionSuccess,
  getLabNoOfUsersSuccess,
  getAllLabByWorkplaceSuccess,
  getAllFranchiseSuccess,
  getLabTestSuccess,
  getExistingLabTestSuccess,
  addLaboratorySuccess,
  getLaboratoryDetailsSuccess,
  addLaboratoryUserSuccess,
} from './actions';
import { alertDialog } from '../../../helpers/commonHelper';
import ErrorMessage from '../../../constants/errorMessage';

const getAllLaboratoriesAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  const apiUrl = `${GET_ALL_LABORATORIES_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${apiUrl + searchStr + typeStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const addLabUserAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('POST', ADD_LAB_USER_EP, item.payload);
  return data;
};

const verifyLaboratoryAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('POST', VERIFY_LABORATORY_EP, item.payload);
  return data;
};

const declineLaboratoryAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('POST', VERIFY_LABORATORY_EP, item.payload);
  return data;
};

const labOrdersAsync = async (item) => {
  let data = null;
  let apiUrl = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  if (item.payload.userId) {
    apiUrl = `${GET_LAB_ORDERS_EP}patient_id=${item.payload.userId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;
  }

  if (item.payload.labUserId) {
    apiUrl = `${GET_LAB_ORDERS_EP}lab_user_id=${item.payload.labUserId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;
  }

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', `${apiUrl + searchStr + typeStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const getLabUserDetailsByIdAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT(
    'GET',
    `${LAB_USER_DETAILS_BY_ID_EP}${item.payload.LabDetailsId}`
  );
  return data;
};

const setUpdateHomeReportCollectionAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT(
    'POST',
    UPDATE_REPORT_HOME_COLLECTION_EP,
    item.payload
  );

  return res;
};

function* getAllLaboratories(payload) {
  try {
    yield put(setOrderLoader(true));
    const allLabList = yield call(getAllLaboratoriesAsync, payload);
    yield put(setOrderLoader(false));
    if (allLabList.data.success) {
      yield put(getAllLaboratoriesSuccess(allLabList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchLaboratoriesList() {
  yield takeLatest(GET_ALL_LABORATORIES, getAllLaboratories);
}

const getAllLabUserAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const typeStr =
    item.payload.type !== null ? `&type=${item.payload.type}` : '';

  const apiUrl = `${GET_ALL_LAB_USER_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + typeStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr + typeStr);
  }
  return data;
};

const labOrderDetailsAsync = async (item) => {
  const apiUrl = `order_id=${item.payload.order_id}&lab_user_id=${item.payload.lab_user_id}&user_id=${item.payload.user_id}&prescription_type=${item.payload.prescription_type}&inprocess=${item.payload.inprocess}`;
  const data = await AXIOS_AUTH_KIT('GET', GET_LAB_ORDER_DETAILS_EP + apiUrl)
    .then((res) => res)
    .catch((errors) => errors);
  return data;
};

const labNoOFUsersCollectionAsync = async (item) => {
  let data = null;
  const apiUrl = `workplace_id=${item.payload.workplace_id}&offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${GET_LAB_USERS_EP + apiUrl}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', GET_LAB_USERS_EP + apiUrl);
  }
  return data;
};

const allLabByWorkplaceAsync = async (item) => {
  let apiUrl = null;
  if (item.payload.workplace_name) {
    apiUrl = `workplace_name=${item.payload.workplace_name}`;
  } else {
    apiUrl = `franchise=${item.payload.franchise}`;
  }
  const data = await AXIOS_AUTH_KIT(
    'GET',
    GET_ALL_LABS_BY_WORKPLACE_EP + apiUrl
  )
    .then((res) => res)
    .catch((errors) => errors);
  return data;
};

const getLabTestDataAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT('GET', GET_LIBRARY_EP + item.payload.name);
  return res;
};

const getExistingLabTestDataAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT(
    'GET',
    GET_EXISTING_LAB_TESTS_EP + item.payload.lab_id
  );
  return res;
};

const getLaboratoryDetailsAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT(
    'GET',
    GET_LAB_DETAILS_BY_ID_EP + item.payload
  );
  return res;
};

const addLaboratoryDataAsync = async (item) => {
  const res = await AXIOS_AUTH_KIT('POST', ADD_LABORATORY_EP, item.payload);
  return res;
};

function* getAllLabUser(payload) {
  try {
    yield put(setLoader(true));
    const labUserList = yield call(getAllLabUserAsync, payload);
    if (labUserList.data.success) {
      yield put(setLoader(false));
      yield put(getAllLabUserSuccess(labUserList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* addLabUser(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(addLabUserAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      alertDialog('success', ErrorMessage.lab_user_add_msg);
      yield put(setAddLabDetails(null));
      yield put(setIsRedirect(true));
    }
    if (res.data.status === false) {
      if (res.data.message.includes('user_id')) {
        yield put(addLaboratoryUserSuccess(res.data.message));
      } else {
        alertDialog('error', res.data.message);
      }
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getLabUserDetailsById(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getLabUserDetailsByIdAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getLabUserDetailsByIdSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* verifyLaboratory(payload) {
  try {
    yield put(setVerifyLoader(true));
    const res = yield call(verifyLaboratoryAsync, payload);
    yield put(setVerifyLoader(false));
    if (res.data.success) {
      yield put(setVerifyDeclineLaboratorySuccess(res.data));
      alertDialog('success', ErrorMessage.lab_verify_msg);
    }
    if (res.data.status === false) {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* declineLaboratory(payload) {
  try {
    yield put(setDeclineLoader(true));
    const res = yield call(declineLaboratoryAsync, payload);
    yield put(setDeclineLoader(false));
    if (res.data.success) {
      yield put(setVerifyDeclineLaboratorySuccess(res.data));
      alertDialog('success', ErrorMessage.lab_decline_msg);
    }
    if (res.data.status === false) {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* labOrders(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(labOrdersAsync, payload);
    if (res.data.success) {
      yield put(setLoader(false));
      yield put(getLabOrdersSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setUpdateHomeReportCollection(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setUpdateHomeReportCollectionAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(updateHomeReportCollectionSuccess(res.data.data));
      alertDialog(
        'success',
        ErrorMessage.pharmacy_delivery_discount_update_msg
      );
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* labOrderDetails(payload) {
  try {
    yield put(setOrderLoader(true));
    const resData = yield call(labOrderDetailsAsync, payload);
    yield put(setOrderLoader(false));
    if (resData.data.success) {
      yield put(getLabOrderDetailsSuccess(resData.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* labNoOFUsers(payload) {
  try {
    // yield put(setLoader(true));
    const res = yield call(labNoOFUsersCollectionAsync, payload);
    // if (res.data.success) {
    yield put(setLoader(false));
    yield put(getLabNoOfUsersSuccess(res.data.data));
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* allLabByWorkplace(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(allLabByWorkplaceAsync, payload);
    // if (res.data.success) {
    yield put(setLoader(false));
    yield put(getAllLabByWorkplaceSuccess(res.data.data));
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* allLabFranchise(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(allLabByWorkplaceAsync, payload);
    // if (res.data.success) {
    yield put(setLoader(false));
    yield put(getAllFranchiseSuccess(res.data.data));
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getLabTestData(payload) {
  try {
    const resData = yield call(getLabTestDataAsync, payload);
    if (resData.data.success) {
      yield put(getLabTestSuccess(resData.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getExistingLabTestData(payload) {
  try {
    const resData = yield call(getExistingLabTestDataAsync, payload);
    if (resData.data.success) {
      yield put(getExistingLabTestSuccess(resData.data.data.total_test));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* addLaboratoryData(payload) {
  try {
    setSubmitLoader(true);
    const resData = yield call(addLaboratoryDataAsync, payload);
    if (resData.data.success) {
      yield put(addLaboratorySuccess(resData.data.data));
    }
    if (resData.data.status === false) {
      alertDialog('error', resData.data.message);
    }
    setSubmitLoader(false);
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getLaboratoryDetails(payload) {
  try {
    setTableDataLoader(true);
    const resData = yield call(getLaboratoryDetailsAsync, payload);
    setTableDataLoader(true);
    if (resData.data.success) {
      yield put(getLaboratoryDetailsSuccess(resData.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchLabUserList() {
  yield takeLatest(GET_ALL_LAB_USER, getAllLabUser);
}

export function* watchAddLabUser() {
  yield takeLatest(ADD_LAB_USER, addLabUser);
}

export function* watchLabUserDetailsById() {
  yield takeLatest(GET_LAB_USER_PROFILE_DETAILS_BY_ID, getLabUserDetailsById);
}

export function* watchVerifyLaboratory() {
  yield takeLatest(SET_VERIFY_LABORATORY, verifyLaboratory);
}

export function* watchDeclineLaboratory() {
  yield takeLatest(SET_DECLINE_LABORATORY, declineLaboratory);
}

export function* watchLabyOrders() {
  yield takeLatest(GET_LAB_ORDERS, labOrders);
}

export function* watchLabOrderDetails() {
  yield takeLatest(GET_LAB_ORDER_DETAILS, labOrderDetails);
}

export function* watchUpdateHomeReportCollection() {
  yield takeLatest(
    UPDATE_REPORT_HOME_COLLECTION,
    setUpdateHomeReportCollection
  );
}

export function* watchLabNoOFUsers() {
  yield takeLatest(GET_LAB_NO_OF_USERS, labNoOFUsers);
}

export function* watchAllLabByWorkplace() {
  yield takeLatest(GET_ALL_LAB_BY_WORKPLACE, allLabByWorkplace);
}

export function* watchAllLabFranchise() {
  yield takeLatest(GET_ALL_FRANCHISE, allLabFranchise);
}

export function* watchLabTestList() {
  yield takeLatest(GET_LAB_TEST_DATA, getLabTestData);
}

export function* watchExistingLabTestList() {
  yield takeLatest(GET_EXISTING_LAB_TEST_DATA, getExistingLabTestData);
}

export function* watchAddLaboratory() {
  yield takeLatest(ADD_LABORATORY, addLaboratoryData);
}

export function* watchGetLaboratoryDetails() {
  yield takeLatest(GET_LABORATORY_DETAILS, getLaboratoryDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchLaboratoriesList),
    fork(watchLabUserList),
    fork(watchAddLabUser),
    fork(watchLabUserDetailsById),
    fork(watchVerifyLaboratory),
    fork(watchDeclineLaboratory),
    fork(watchLabyOrders),
    fork(watchLabOrderDetails),
    fork(watchUpdateHomeReportCollection),
    fork(watchLabNoOFUsers),
    fork(watchAllLabByWorkplace),
    fork(watchAllLabFranchise),
    fork(watchLabTestList),
    fork(watchExistingLabTestList),
    fork(watchAddLaboratory),
    fork(watchGetLaboratoryDetails),
  ]);
}
