import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../helpers/apiClient';
import {
  GET_DRUG_INFO_LIST,
  GET_LIBRARY_LIST,
  GET_AWAITING_APPROVAL_LIBRARY_LIST,
  SAVE_LIBRARY,
  GET_COMMON_HEALTH_CONCERN_LIST,
  GET_COMMON_SPECIALITIES_LIST,
  SET_TIMEOUT,
  SAVE_TESTS,
  SAVE_MERGE_ALLERGIES,
  DELETE_LIBRARY,
  GET_MERGED_ALLERGY_LIST,
  GET_TIMEOUT,
} from '../actions';
import {
  GET_LIBRARY_EP,
  SAVE_LIBRARY_EP,
  TIMEOUT_EP,
  SAVE_TESTS_EP,
  SAVE_MERGE_ALLERGIES_EP,
  DELETE_LIBRARY_EP,
  MERGED_ALLERGIES_EP,
  GET_TIMEOUT_EP,
} from '../../constants/apiEndPoints';

import {
  getDrugInfoListSuccess,
  getLibrarySuccess,
  saveLibrarySuccess,
  getAwaitingApprovalLibrarySuccess,
  getCommonSpecialitiesListSuccess,
  getCommonHealthConcernListSuccess,
  setTimeoutSuccess,
  saveTestsSuccess,
  saveMergeAllergiesSuccess,
  deleteLibrarySuccess,
  getMergedAllergyListSuccess,
  getTimeOutSuccess,
} from './actions';
import { setListLoader, setSubmitLoader, setLoader } from '../common/actions';
import { alertDialog } from '../../helpers/commonHelper';
import ErrorMessage from '../../constants/errorMessage';

const getDrugListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const drugStatus =
    item.payload.drug_status !== '' && item.payload.drug_status !== undefined
      ? `&drug_status=${item.payload.drug_status}`
      : '';
  const drugParentId =
    item.payload.is_parent !== '' && item.payload.is_parent !== undefined
      ? `&is_parent=${item.payload.is_parent}`
      : '';
  const drugId =
    item.payload.id !== '' && item.payload.id !== undefined
      ? `&id=${item.payload.id}`
      : '';

  const apiUrl = `${GET_LIBRARY_EP}${item.payload.library_name}&is_admin=1&limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + drugStatus + drugParentId + drugId}&sort=${
        item.payload.sort
      }&order=${item.payload.order}`
    );
  } else {
    data = await AXIOS_AUTH_KIT(
      'GET',
      apiUrl + searchStr + drugStatus + drugParentId + drugId
    );
  }
  return data;
};

function* getDrugList(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getDrugListAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getDrugInfoListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

const getLibraryListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' && item.payload.search !== undefined
      ? `&search=${item.payload.search}`
      : '';
  const statusStr =
    item.payload.status !== null && item.payload.status !== undefined
      ? `&status=${item.payload.status}`
      : '';

  const drugManufacturer =
    item.payload.drug_manufacturer !== null &&
    item.payload.drug_manufacturer !== undefined
      ? `&drug_manufacturer=${item.payload.drug_manufacturer}`
      : '';
  const drugUnit =
    item.payload.drug_unit !== null && item.payload.drug_unit !== undefined
      ? `&drug_unit=${item.payload.drug_unit}`
      : '';
  const drugRoute =
    item.payload.drug_route !== null && item.payload.drug_route !== undefined
      ? `&drug_route=${item.payload.drug_route}`
      : '';
  const drugStatus =
    item.payload.drug_status !== null && item.payload.drug_status !== undefined
      ? `&drug_status=${item.payload.drug_status}`
      : '';

  const apiUrl = `${GET_LIBRARY_EP}${
    item.payload.library_name +
    statusStr +
    drugManufacturer +
    drugUnit +
    drugRoute +
    drugStatus
  }&is_admin=1&limit=${item.payload.limit}&offset=${item.payload.offset}`;

  console.log('statusstatusstatus', apiUrl);

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr);
  }
  return data;
};

const setSaveLibraryAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', SAVE_LIBRARY_EP, item.payload);
  return data;
};

const timeoutAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', TIMEOUT_EP, item.payload);
  return data;
};

const setSaveTestsAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', SAVE_TESTS_EP, item.payload);
  return data;
};

const mergeAllergiesAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT(
    'POST',
    SAVE_MERGE_ALLERGIES_EP,
    item.payload
  );
  return data;
};

const setDeleteLibraryAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('POST', DELETE_LIBRARY_EP, item.payload);
  return data;
};

function* getLibraryList(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getLibraryListAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getLibrarySuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

const getCommonLibraryAsync = async (item) => {
  const data = AXIOS_AUTH_KIT(
    'GET',
    GET_LIBRARY_EP + item.payload.library_name
  );
  return data;
};

const getTimeoutDataAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('GET', GET_TIMEOUT_EP + item.payload);
  return data;
};

const getMergedAllergListDataAsync = async (item) => {
  let data = null;
  const apiUrl = `${MERGED_ALLERGIES_EP}/${item.payload.id}?limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl);
  }
  return data;
};

function* getAwaitingApprovalLibraryList(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(getLibraryListAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getAwaitingApprovalLibrarySuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setSaveLibrary(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setSaveLibraryAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(saveLibrarySuccess(res.data.data));
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getCommonSpecialities(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getCommonLibraryAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getCommonSpecialitiesListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getCommonHealthConcern(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getCommonLibraryAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getCommonHealthConcernListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* timeout(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(timeoutAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(setTimeoutSuccess(res.data.data));
    } else if (res.status === 400) {
      alertDialog('error', res.data[0].constraints);
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setSaveTests(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setSaveTestsAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(saveTestsSuccess(res.data.data));
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* mergeAllergies(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(mergeAllergiesAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      alertDialog('success', res.data.data.msg);
      yield put(saveMergeAllergiesSuccess(res.data.data));
    } else {
      alertDialog('success', res.data.data.msg);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setDeleteLibrary(payload) {
  try {
    yield put(setSubmitLoader(true));
    const res = yield call(setDeleteLibraryAsync, payload);
    yield put(setSubmitLoader(false));
    if (res.data.success) {
      yield put(deleteLibrarySuccess(res.data.data));
      if (payload.payload.status === true) {
        alertDialog('success', ErrorMessage.library_active);
      } else {
        alertDialog('success', ErrorMessage.library_deactive);
      }
    } else {
      alertDialog('error', res.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getMergedAllergListData(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getMergedAllergListDataAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getMergedAllergyListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getTimeoutData(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getTimeoutDataAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getTimeOutSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchDrugList() {
  yield takeLatest(GET_DRUG_INFO_LIST, getDrugList);
}

export function* watchAwaitingApprovalLibraryList() {
  yield takeLatest(
    GET_AWAITING_APPROVAL_LIBRARY_LIST,
    getAwaitingApprovalLibraryList
  );
}

export function* watchLibraryList() {
  yield takeLatest(GET_LIBRARY_LIST, getLibraryList);
}

export function* watchSaveLibrary() {
  yield takeLatest(SAVE_LIBRARY, setSaveLibrary);
}

export function* watchCommonSpecialities() {
  yield takeLatest(GET_COMMON_SPECIALITIES_LIST, getCommonSpecialities);
}

export function* watchCommonHealthConcern() {
  yield takeLatest(GET_COMMON_HEALTH_CONCERN_LIST, getCommonHealthConcern);
}

export function* watchTimeout() {
  yield takeLatest(SET_TIMEOUT, timeout);
}

export function* watchSetSaveTests() {
  yield takeLatest(SAVE_TESTS, setSaveTests);
}

export function* watchMergeAllergies() {
  yield takeLatest(SAVE_MERGE_ALLERGIES, mergeAllergies);
}

export function* watchDeleteLibrary() {
  yield takeLatest(DELETE_LIBRARY, setDeleteLibrary);
}

export function* watchMergedAllergiesList() {
  yield takeLatest(GET_MERGED_ALLERGY_LIST, getMergedAllergListData);
}

export function* watchGetTimeOutData() {
  yield takeLatest(GET_TIMEOUT, getTimeoutData);
}

export default function* rootSaga() {
  yield all([fork(watchDrugList)]);
  yield all([fork(watchLibraryList)]);
  yield all([fork(watchAwaitingApprovalLibraryList)]);
  yield all([fork(watchSaveLibrary)]);
  yield all([fork(watchCommonSpecialities)]);
  yield all([fork(watchCommonHealthConcern)]);
  yield all([fork(watchTimeout)]);
  yield all([fork(watchSetSaveTests)]);
  yield all([fork(watchMergeAllergies)]);
  yield all([fork(watchDeleteLibrary)]);
  yield all([fork(watchMergedAllergiesList)]);
  yield all([fork(watchGetTimeOutData)]);
}
