import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_SPECIALITIES_LIST,
  GET_AWAITING_APPROVAL_LIST,
  GET_HEALTH_CONCERN_LIST,
} from '../../actions';
import { GET_LIBRARY_EP } from '../../../constants/apiEndPoints';

import {
  getSpecialitiesListSuccess,
  getAwaitingApprovalListSuccess,
  getHealthConcernListSuccess,
} from './actions';
import { setLoader, setListLoader } from '../../common/actions';

const getAwaitingApprovalAsync = async (item) => {
  let data = null;
  const apiUrl = `${GET_LIBRARY_EP}${item.payload.library_name}&is_admin=1&status=0&limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl);
  }
  return data;
};

const getSpecialitiesAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const apiUrl = `${GET_LIBRARY_EP}${item.payload.library_name}&is_admin=1&limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr);
  }
  return data;
};

const getHealthConcernAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const apiUrl = `${GET_LIBRARY_EP}${item.payload.library_name}&is_admin=1&limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr);
  }
  return data;
};

function* getSpecialities(payload) {
  try {
    yield put(setListLoader(true));
    const PendingList = yield call(getSpecialitiesAsync, payload);
    if (PendingList.data.success) {
      yield put(setListLoader(false));
      yield put(getSpecialitiesListSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getAwaitingApproval(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getAwaitingApprovalAsync, payload);
    if (PendingList.data.success) {
      yield put(setLoader(false));
      yield put(getAwaitingApprovalListSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getHealthConcern(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getHealthConcernAsync, payload);
    if (PendingList.data.success) {
      yield put(setLoader(false));
      yield put(getHealthConcernListSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchSpecialities() {
  yield takeLatest(GET_SPECIALITIES_LIST, getSpecialities);
}

export function* watchAwaitingApproval() {
  yield takeLatest(GET_AWAITING_APPROVAL_LIST, getAwaitingApproval);
}

export function* watchHealthConcern() {
  yield takeLatest(GET_HEALTH_CONCERN_LIST, getHealthConcern);
}

export default function* rootSaga() {
  yield all([fork(watchSpecialities)]);
  yield all([fork(watchAwaitingApproval)]);
  yield all([fork(watchHealthConcern)]);
}
