import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import patient from './userManagement/patient/reducer';
import doctor from './userManagement/doctor/reducer';
import staff from './userManagement/staff/reducer';
import laboratory from './userManagement/laboratory/reducer';
import dashboard from './dashboard/reducer';
import common from './common/reducer';
import pharmacist from './userManagement/pharmacist/reducer';
import role from './roleManagament/reducer';
import app from './appManagement/reducer';
import notification from './notification/reducer';
import addresses from './appManagement/addresses/reducer';
import specialities from './appManagement/specialities/reducer';
import drugInformation from './appManagement/drugInformation/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  patient,
  doctor,
  dashboard,
  common,
  pharmacist,
  staff,
  laboratory,
  role,
  app,
  notification,
  addresses,
  specialities,
  drugInformation,
});

export default reducers;
