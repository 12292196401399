import {
  PATIENT_PROFILE_BY_ID,
  PATIENT_PROFILE_BY_ID_SUCCESS,
  PATIENT_LIST_PAGINATION,
  DOCTOR_APPOINTMENT_LIST_PAGINATION,
  GET_ALL_DOCTOR_APPOINTMENTS,
  GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS,
  GET_ALL_PATIENT_LIST,
  GET_ALL_PATIENT_LIST_SUCCESS,
  LINKED_LIST_PAGINATION,
  GET_LINKED_ACCOUNT_LIST,
  GET_LINKED_ACCOUNT_LIST_SUCCESS,
  MINOR_LIST_PAGINATION,
  GET_MINOR_ACCOUNT_LIST,
  GET_MINOR_ACCOUNT_LIST_SUCCESS,
  GET_MINOR_ACCOUNT_DETAILS,
  GET_MINOR_ACCOUNT_DETAILS_SUCCESS,
} from '../../actions';

export const getPatientProfileById = (payload) => {
  return {
    type: PATIENT_PROFILE_BY_ID,
    payload,
  };
};

export const getPatientProfileByIdSuccess = (payload) => {
  return {
    type: PATIENT_PROFILE_BY_ID_SUCCESS,
    payload,
  };
};

export const setPatientListPagination = (payload) => {
  return {
    type: PATIENT_LIST_PAGINATION,
    payload,
  };
};

export const setDoctorAppointmentListPagination = (payload) => {
  return {
    type: DOCTOR_APPOINTMENT_LIST_PAGINATION,
    payload,
  };
};

export const AllDoctorAppointments = (payload) => {
  return {
    type: GET_ALL_DOCTOR_APPOINTMENTS,
    payload,
  };
};

export const AllDoctorAppointmentsSuccess = (payload) => {
  return {
    type: GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS,
    payload,
  };
};

export const getAllPatientList = (payload) => {
  return {
    type: GET_ALL_PATIENT_LIST,
    payload,
  };
};

export const getAllPatientListSuccess = (payload) => {
  return {
    type: GET_ALL_PATIENT_LIST_SUCCESS,
    payload,
  };
};

export const setLinkedListPagination = (payload) => {
  return {
    type: LINKED_LIST_PAGINATION,
    payload,
  };
};

export const getLinkedAccountList = (payload) => {
  return {
    type: GET_LINKED_ACCOUNT_LIST,
    payload,
  };
};

export const getLinkedAccountListSuccess = (payload) => {
  return {
    type: GET_LINKED_ACCOUNT_LIST_SUCCESS,
    payload,
  };
};

export const setMinorListPagination = (payload) => {
  return {
    type: MINOR_LIST_PAGINATION,
    payload,
  };
};

export const getMinorAccountList = (payload) => {
  return {
    type: GET_MINOR_ACCOUNT_LIST,
    payload,
  };
};

export const getMinorAccountListSuccess = (payload) => {
  return {
    type: GET_MINOR_ACCOUNT_LIST_SUCCESS,
    payload,
  };
};

export const getMinorAccountDetails = (payload) => {
  return {
    type: GET_MINOR_ACCOUNT_DETAILS,
    payload,
  };
};

export const getMinorAccountDetailsSuccess = (payload) => {
  return {
    type: GET_MINOR_ACCOUNT_DETAILS_SUCCESS,
    payload,
  };
};
