import {
  ROLE_LIST_PAGINATION,
  ROLE_USERS_LIST_PAGINATION,
  GET_ROLE_TYPE_LIST,
  GET_ROLE_TYPE_LIST_SUCCESS,
  GET_ROLE_NAME_LIST,
  GET_ROLE_NAME_LIST_SUCCESS,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  GET_ROLE_USERS,
  GET_ROLE_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  SET_USER_DETAILS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  GET_PERMISSION_DETAILS,
  GET_PERMISSION_DETAILS_SUCCESS,
  UPDATE_PERMISSION_ROLE,
  UPDATE_PERMISSION_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_SUCCESS,
} from '../actions';

const INIT_STATE = {
  roleListPagination: [],
  roleUsersPagination: [],
  roleTypeList: null,
  roleList: null,
  roleSuccess: null,
  roleUsersList: null,
  updateSuccess: null,
  permissionDetails: null,
  updaterole: null,
  roleDeleteSuccess: null,
  userDeleteSuccess: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_LIST_PAGINATION:
      return { ...state, roleListPagination: action.payload };

    case ROLE_USERS_LIST_PAGINATION:
      return { ...state, roleUsersPagination: action.payload };

    case GET_ROLE_TYPE_LIST:
      return { ...state, loading: true, error: '' };

    case GET_ROLE_TYPE_LIST_SUCCESS:
      return { ...state, roleTypeList: action.payload };

    case GET_ROLE_NAME_LIST:
      return { ...state, loading: true, error: '' };

    case GET_ROLE_NAME_LIST_SUCCESS:
      return { ...state, roleList: action.payload };

    case ADD_ROLE:
      return { ...state, loading: true, error: '' };

    case ADD_ROLE_SUCCESS:
      return { ...state, roleSuccess: action.payload };

    case GET_ROLE_USERS:
      return { ...state, loading: true, error: '' };

    case GET_ROLE_USERS_SUCCESS:
      return { ...state, roleUsersList: action.payload };

    case ADD_USER:
      return { ...state, loading: true, error: '' };

    case ADD_USER_SUCCESS:
      return { ...state, userSuccess: action.payload };

    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };

    case UPDATE_USER:
      return { ...state, loading: true, error: '' };

    case UPDATE_USER_SUCCESS:
      return { ...state, updateSuccess: action.payload };

    case GET_PERMISSION_DETAILS:
      return { ...state, loading: true, error: '' };

    case GET_PERMISSION_DETAILS_SUCCESS:
      return { ...state, permissionDetails: action.payload };

    case UPDATE_PERMISSION_ROLE:
      return { ...state, loading: true, error: '' };

    case UPDATE_PERMISSION_ROLE_SUCCESS:
      return { ...state, updaterole: action.payload };

    case DELETE_ROLE:
      return { ...state, loading: true, error: '' };

    case DELETE_ROLE_SUCCESS:
      return { ...state, roleDeleteSuccess: action.payload };

    case DELETE_ADMIN_USER:
      return { ...state, loading: true, error: '' };

    case DELETE_ADMIN_USER_SUCCESS:
      return { ...state, userDeleteSuccess: action.payload };

    default:
      return { ...state };
  }
};
