import {
  SPECIALITIES_LIST_PAGINATION,
  GET_SPECIALITIES_LIST,
  GET_SPECIALITIES_LIST_SUCCESS,
  AWAITING_APPROVAL_PAGINATION,
  GET_AWAITING_APPROVAL_LIST,
  GET_AWAITING_APPROVAL_LIST_SUCCESS,
  HEALTH_CONCERN_PAGINATION,
  GET_HEALTH_CONCERN_LIST,
  GET_HEALTH_CONCERN_LIST_SUCCESS,

} from '../../actions';

const INIT_STATE = {
  specialitiesPagination: [],
  awaitingApprovalPagination: [],
  healthConcernPagination: [],
  specialitiesList: null,
  awaitingApprovalList: null,
  healthConcernList: null,
  commonSpecialitiesList: null,
  librarySuccess: null,
  CommonHealthConcernList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPECIALITIES_LIST_PAGINATION:
      return { ...state, specialitiesPagination: action.payload };

    case GET_SPECIALITIES_LIST:
      return { ...state, loading: true, error: '' };

    case GET_SPECIALITIES_LIST_SUCCESS:
      return { ...state, specialitiesList: action.payload };

    case AWAITING_APPROVAL_PAGINATION:
      return { ...state, awaitingApprovalPagination: action.payload };

    case GET_AWAITING_APPROVAL_LIST:
      return { ...state, loading: true, error: '' };

    case GET_AWAITING_APPROVAL_LIST_SUCCESS:
      return { ...state, awaitingApprovalList: action.payload };

    case HEALTH_CONCERN_PAGINATION:
      return { ...state, healthConcernPagination: action.payload };

    case GET_HEALTH_CONCERN_LIST:
      return { ...state, loading: true, error: '' };

    case GET_HEALTH_CONCERN_LIST_SUCCESS:
      return { ...state, healthConcernList: action.payload };


    default:
      return { ...state };
  }
};
