/* eslint-disable import/prefer-default-export */
import {
  DOCTOR_LIST_PAGINATION,
  DOCTOR_STAFF_ID,
  GET_ALL_DELEGATES,
  GET_ALL_PATIENT_APPOINTMENTS,
  GET_ALL_PATIENT_APPOINTMENTS_SUCCESS,
  GET_ALL_DOCTOR_AND_STAFF,
  GET_ALL_DOCTOR_AND_STAFF_SUCCESS,
  GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID,
  GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS,
} from '../../actions';

export const setDoctorListPagination = (payload) => {
  return {
    type: DOCTOR_LIST_PAGINATION,
    payload,
  };
};

export const setDoctorStaffListId = (payload) => {
  return {
    type: DOCTOR_STAFF_ID,
    payload,
  };
};

export const getAllDelegates = (payload) => {
  return {
    type: GET_ALL_DELEGATES,
    payload,
  };
};

export const AllPatientAppointments = (payload) => {
  return {
    type: GET_ALL_PATIENT_APPOINTMENTS,
    payload,
  };
};

export const AllPatientAppointmentsSuccess = (payload) => {
  return {
    type: GET_ALL_PATIENT_APPOINTMENTS_SUCCESS,
    payload,
  };
};

export const getAllDoctorAndStaff = (payload) => {
  return {
    type: GET_ALL_DOCTOR_AND_STAFF,
    payload,
  };
};

export const getAllDoctorAndStaffSuccess = (payload) => {
  return {
    type: GET_ALL_DOCTOR_AND_STAFF_SUCCESS,
    payload,
  };
};

export const getDoctorStaffDetailsById = (payload) => {
  return {
    type: GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID,
    payload,
  };
};

export const getDoctorStaffDetailsByIdSuccess = (payload) => {
  return {
    type: GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS,
    payload,
  };
};
