import {
  HOSPITAL_ADDRESSES_PAGINATION,
  GET_HOSPITAL_ADDRESSES,
  GET_HOSPITAL_ADDRESSES_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  hospitalAddressesPagination: [],
  hospitalAddressesList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HOSPITAL_ADDRESSES_PAGINATION:
      return { ...state, hospitalAddressesPagination: action.payload };

    case GET_HOSPITAL_ADDRESSES:
      return { ...state, loading: true, error: '' };

    case GET_HOSPITAL_ADDRESSES_SUCCESS:
      return { ...state, hospitalAddressesList: action.payload };

    default:
      return { ...state };
  }
};
