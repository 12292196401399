import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../helpers/apiClient';
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION,
} from '../actions';
import {
  GET_PATIENT_NOTIFICATION_EP,
  GET_NOTIFICATION_DETAILS_EP,
  UPDATE_NOTIFICATION_EP,
} from '../../constants/apiEndPoints';

import {
  getNotificationListSuccess,
  getNotificationDetailsSuccess,
  updateNotificationSuccess,
} from './actions';
import { setLoader } from '../common/actions';
import { alertDialog } from '../../helpers/commonHelper';

const getAllPendingListAsync = async (item) => {
  let data = null;
  const apiUrl = `${GET_PATIENT_NOTIFICATION_EP}offset=${item.payload.offset}&limit=${item.payload.limit}&role_type=${item.payload.role_type}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl);
  }
  return data;
};

const getNotificationByIdAsync = async (item) => {
  const data = AXIOS_AUTH_KIT(
    'GET',
    GET_NOTIFICATION_DETAILS_EP + item.payload.key
  );
  return data;
};

const setUpdateNotificationAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', UPDATE_NOTIFICATION_EP, item.payload);
  return data;
};

function* getPatientNotificationList(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getAllPendingListAsync, payload);
    if (PendingList.data.success) {
      yield put(setLoader(false));
      yield put(getNotificationListSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getNotificationById(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getNotificationByIdAsync, payload);
    if (PendingList.data.success) {
      yield put(setLoader(false));
      yield put(getNotificationDetailsSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setUpdateNotification(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(setUpdateNotificationAsync, payload);
    yield put(setLoader(false));
    if (PendingList.data.success) {
      alertDialog('success', PendingList.data.data.msg);
      yield put(updateNotificationSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchPatientNotifiction() {
  yield takeLatest(GET_NOTIFICATION, getPatientNotificationList);
}

export function* watchNotifictionDetails() {
  yield takeLatest(GET_NOTIFICATION_DETAILS, getNotificationById);
}

export function* watchUpdateNotifiction() {
  yield takeLatest(UPDATE_NOTIFICATION, setUpdateNotification);
}

export default function* rootSaga() {
  yield all([fork(watchPatientNotifiction)]);
  yield all([fork(watchNotifictionDetails)]);
  yield all([fork(watchUpdateNotifiction)]);
}
