import {
  all,
  call,
  fork,
  put,
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import apiClient, { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_ALL_DOCTOR_APPOINTMENTS,
  GET_ALL_PATIENT_LIST,
  PATIENT_PROFILE_BY_ID,
  GET_LINKED_ACCOUNT_LIST,
  GET_MINOR_ACCOUNT_LIST,
  GET_MINOR_ACCOUNT_DETAILS,
} from '../../actions';
import {
  GET_ALL_PATIENT_APPOINTMENTS_EP,
  GET_ALL_PATIENTS_EP,
  GET_PATIENT_EP,
  GET_LINKED_LIST_EP,
  GET_MINOR_LIST_EP,
  GET_MINOR_DETAILS_EP,
} from '../../../constants/apiEndPoints';
import {
  AllDoctorAppointmentsSuccess,
  getAllPatientListSuccess,
  getPatientProfileByIdSuccess,
  getLinkedAccountListSuccess,
  getMinorAccountListSuccess,
  getMinorAccountDetailsSuccess,
} from './actions';
import {
  setLoader,
  setListLoader,
  setTableDataLoader,
} from '../../common/actions';

const getAllDoctorAppointmentAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const statusStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  const apiUrl = `${GET_ALL_PATIENT_APPOINTMENTS_EP}patient_id=${item.payload.patientId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await apiClient
      .get(
        `${apiUrl + searchStr + statusStr}&sort=${item.payload.sort}&order=${
          item.payload.order
        }`
      )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await apiClient
      .get(`${apiUrl + searchStr + statusStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

function* getAllDoctorAppointments(payload) {
  try {
    yield put(setListLoader(true));
    const patientAppointmentList = yield call(
      getAllDoctorAppointmentAsync,
      payload
    );
    yield put(setListLoader(false));
    if (patientAppointmentList.data.success) {
      yield put(AllDoctorAppointmentsSuccess(patientAppointmentList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchPatientList() {
  yield takeLatest(GET_ALL_PATIENT_LIST, getAllPatientList);
}

const getAllPatientListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const statusStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';

  const apiUrl = `${GET_ALL_PATIENTS_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await apiClient
      .get(
        `${apiUrl + searchStr + statusStr}&sort=${item.payload.sort}&order=${
          item.payload.order
        }`
      )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await apiClient
      .get(`${apiUrl + searchStr + statusStr}`)
      .then((res) => res)
      .catch((errors) => errors);
  }

  return data;
};

function* getAllPatientList(payload) {
  try {
    yield put(setLoader(true));
    const patientList = yield call(getAllPatientListAsync, payload);
    yield put(setLoader(false));
    if (patientList.data.success) {
      yield put(getAllPatientListSuccess(patientList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchPatientDetailsByID() {
  yield takeLatest(PATIENT_PROFILE_BY_ID, getAllPatientDetailsById);
}

const getAllPatientDetailsByIdAsync = async (item) => {
  const data = await apiClient
    .get(GET_PATIENT_EP + item.payload.PatientId)
    .then((res) => res)
    .catch((errors) => errors);

  return data;
};

const getLinkedListAsync = async (item) => {
  let data;
  const apiUrl = GET_LINKED_LIST_EP + item.payload.PatientId;
  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', apiUrl)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const getMinorListAsync = async (item) => {
  let data;
  const apiUrl = GET_MINOR_LIST_EP + item.payload.PatientId;
  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl}&sort=${item.payload.sort}&order=${item.payload.order}`
    )
      .then((res) => res)
      .catch((errors) => errors);
  } else {
    data = await AXIOS_AUTH_KIT('GET', apiUrl)
      .then((res) => res)
      .catch((errors) => errors);
  }
  return data;
};

const getMinorDetailsAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('GET', GET_MINOR_DETAILS_EP + item.payload)
    .then((res) => res)
    .catch((errors) => errors);
  return data;
};

function* getAllPatientDetailsById(payload) {
  try {
    yield put(setTableDataLoader(true));
    const doctorAndStaffById = yield call(
      getAllPatientDetailsByIdAsync,
      payload
    );

    if (doctorAndStaffById.data.success) {
      yield put(setTableDataLoader(false));
      yield put(getPatientProfileByIdSuccess(doctorAndStaffById.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getLinkedList(payload) {
  try {
    yield put(setListLoader(true));
    const res = yield call(getLinkedListAsync, payload);
    yield put(setListLoader(false));
    if (res.data.success) {
      yield put(getLinkedAccountListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getMinorList(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(getMinorListAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getMinorAccountListSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getMinorDetails(payload) {
  try {
    yield put(setLoader(true));
    const res = yield call(getMinorDetailsAsync, payload);
    yield put(setLoader(false));
    if (res.data.success) {
      yield put(getMinorAccountDetailsSuccess(res.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchDoctorAppointMents() {
  yield takeLatest(GET_ALL_DOCTOR_APPOINTMENTS, getAllDoctorAppointments);
}

export function* watchgetLinkedList() {
  yield takeLatest(GET_LINKED_ACCOUNT_LIST, getLinkedList);
}

export function* watchgetMinorList() {
  yield takeLatest(GET_MINOR_ACCOUNT_LIST, getMinorList);
}

export function* watchgetMinorDetails() {
  yield takeLatest(GET_MINOR_ACCOUNT_DETAILS, getMinorDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchDoctorAppointMents),
    fork(watchPatientList),
    fork(watchPatientDetailsByID),
    fork(watchgetLinkedList),
    fork(watchgetMinorList),
    fork(watchgetMinorDetails),
  ]);
}
