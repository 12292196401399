import {
  SET_DRUG_PAGINATION,
  GET_DRUG_HISTORY,
  GET_DRUG_HISTORY_SUCCESS,
  GET_MERGED_DRUG,
  GET_MERGED_DRUG_SUCCESS,
  SAVE_DRUG,
  SAVE_DRUG_SUCCESS,
  GET_DRUG_DETAILS_BY_ID,
  GET_DRUG_DETAILS_BY_ID_SUCCESS,
  SAVE_MERGE_DRUG,
  SAVE_MERGE_DRUG_SUCCESS,
  GET_PHARMACIES_DRUG,
  GET_PHARMACIES_DRUG_SUCCESS,
} from '../../actions';

export const setDrugPagination = (payload) => {
  return {
    type: SET_DRUG_PAGINATION,
    payload,
  };
};

export const getDrugHistory = (payload) => {
  return {
    type: GET_DRUG_HISTORY,
    payload,
  };
};

export const getDrugHistorySuccess = (payload) => {
  return {
    type: GET_DRUG_HISTORY_SUCCESS,
    payload,
  };
};

export const getMergedDrug = (payload) => {
  return {
    type: GET_MERGED_DRUG,
    payload,
  };
};

export const getMergedDrugSuccess = (payload) => {
  return {
    type: GET_MERGED_DRUG_SUCCESS,
    payload,
  };
};

export const saveDrug = (payload) => {
  return {
    type: SAVE_DRUG,
    payload,
  };
};

export const saveDrugSuccess = (payload) => {
  return {
    type: SAVE_DRUG_SUCCESS,
    payload,
  };
};

export const getDrugDetailsById = (payload) => {
  return {
    type: GET_DRUG_DETAILS_BY_ID,
    payload,
  };
};

export const getDrugDetailsByIdSuccess = (payload) => {
  return {
    type: GET_DRUG_DETAILS_BY_ID_SUCCESS,
    payload,
  };
};

export const saveMergeDrug = (payload) => {
  return {
    type: SAVE_MERGE_DRUG,
    payload,
  };
};

export const saveMergeDrugSuccess = (payload) => {
  return {
    type: SAVE_MERGE_DRUG_SUCCESS,
    payload,
  };
};

export const getPharmaciesDrug = (payload) => {
  return {
    type: GET_PHARMACIES_DRUG,
    payload,
  };
};

export const getPharmaciesDrugSuccess = (payload) => {
  return {
    type: GET_PHARMACIES_DRUG_SUCCESS,
    payload,
  };
};
