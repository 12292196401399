import {
  GET_ALL_STAFF_PATIENT_APPOINTMENTS,
  GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS,
  STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION,
} from '../../actions';

const INIT_STATE = {
  staffPatientPaginationHeader: [],
  staffPatientAppointments: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION:
      return { ...state, staffPatientPaginationHeader: action.payload };

    case GET_ALL_STAFF_PATIENT_APPOINTMENTS:
      return { ...state, loading: true, error: '' };

    case GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS:
      return { ...state, staffPatientAppointments: action.payload };

    default:
      return { ...state };
  }
};
