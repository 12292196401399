import {
  DRUG_INFO_LIST_PAGINATION,
  GET_DRUG_INFO_LIST,
  GET_DRUG_INFO_LIST_SUCCESS,
  LIBRARY_LIST_PAGINATION,
  GET_LIBRARY_LIST,
  GET_LIBRARY_LIST_SUCCESS,
  AWAITING_APPROVAL_LIBRARY_PAGINATION,
  GET_AWAITING_APPROVAL_LIBRARY_LIST,
  GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS,
  SAVE_LIBRARY,
  SAVE_LIBRARY_SUCCESS,
  GET_COMMON_SPECIALITIES_LIST,
  GET_COMMON_SPECIALITIES_LIST_SUCCESS,
  GET_COMMON_HEALTH_CONCERN_LIST,
  GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS,
  SET_TIMEOUT,
  SET_TIMEOUT_SUCCESS,
  SAVE_TESTS,
  SAVE_TESTS_SUCCESS,
  SAVE_MERGE_ALLERGIES,
  SAVE_MERGE_ALLERGIES_SUCCESS,
  DELETE_LIBRARY,
  DELETE_LIBRARY_SUCCESS,
  GET_MERGED_ALLERGY_LIST,
  GET_MERGED_ALLERGY_LIST_SUCCESS,
  MERGED_ALLERGY_LIST_PAGINATION,
  GET_TIMEOUT,
  GET_TIMEOUT_SUCCESS,
} from '../actions';

export const setDrugInfoPagination = (payload) => {
  return {
    type: DRUG_INFO_LIST_PAGINATION,
    payload,
  };
};

export const getDrugInfoList = (payload) => {
  return {
    type: GET_DRUG_INFO_LIST,
    payload,
  };
};

export const getDrugInfoListSuccess = (payload) => {
  return {
    type: GET_DRUG_INFO_LIST_SUCCESS,
    payload,
  };
};

export const setLibraryPagination = (payload) => {
  return {
    type: LIBRARY_LIST_PAGINATION,
    payload,
  };
};

export const getLibrary = (payload) => {
  return {
    type: GET_LIBRARY_LIST,
    payload,
  };
};

export const getLibrarySuccess = (payload) => {
  return {
    type: GET_LIBRARY_LIST_SUCCESS,
    payload,
  };
};

export const setAwaitingApprovalLibraryPagination = (payload) => {
  return {
    type: AWAITING_APPROVAL_LIBRARY_PAGINATION,
    payload,
  };
};

export const getAwaitingApprovalLibrary = (payload) => {
  return {
    type: GET_AWAITING_APPROVAL_LIBRARY_LIST,
    payload,
  };
};

export const getAwaitingApprovalLibrarySuccess = (payload) => {
  return {
    type: GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS,
    payload,
  };
};

export const getCommonSpecialitiesList = (payload) => {
  return {
    type: GET_COMMON_SPECIALITIES_LIST,
    payload,
  };
};

export const getCommonSpecialitiesListSuccess = (payload) => {
  return {
    type: GET_COMMON_SPECIALITIES_LIST_SUCCESS,
    payload,
  };
};

export const getCommonHealthConcernList = (payload) => {
  return {
    type: GET_COMMON_HEALTH_CONCERN_LIST,
    payload,
  };
};

export const getCommonHealthConcernListSuccess = (payload) => {
  return {
    type: GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS,
    payload,
  };
};

export const saveLibrary = (payload) => {
  return {
    type: SAVE_LIBRARY,
    payload,
  };
};

export const saveLibrarySuccess = (payload) => {
  return {
    type: SAVE_LIBRARY_SUCCESS,
    payload,
  };
};

export const setTimeout = (payload) => {
  return {
    type: SET_TIMEOUT,
    payload,
  };
};

export const setTimeoutSuccess = (payload) => {
  return {
    type: SET_TIMEOUT_SUCCESS,
    payload,
  };
};

export const saveTests = (payload) => {
  return {
    type: SAVE_TESTS,
    payload,
  };
};

export const saveTestsSuccess = (payload) => {
  return {
    type: SAVE_TESTS_SUCCESS,
    payload,
  };
};

export const saveMergeAllergies = (payload) => {
  return {
    type: SAVE_MERGE_ALLERGIES,
    payload,
  };
};

export const saveMergeAllergiesSuccess = (payload) => {
  return {
    type: SAVE_MERGE_ALLERGIES_SUCCESS,
    payload,
  };
};

export const deleteLibrary = (payload) => {
  return {
    type: DELETE_LIBRARY,
    payload,
  };
};

export const deleteLibrarySuccess = (payload) => {
  return {
    type: DELETE_LIBRARY_SUCCESS,
    payload,
  };
};

export const getMergedAllergyList = (payload) => {
  return {
    type: GET_MERGED_ALLERGY_LIST,
    payload,
  };
};

export const getMergedAllergyListSuccess = (payload) => {
  return {
    type: GET_MERGED_ALLERGY_LIST_SUCCESS,
    payload,
  };
};

export const setMergedAllergyPagination = (payload) => {
  return {
    type: MERGED_ALLERGY_LIST_PAGINATION,
    payload,
  };
};

export const getTimeOut = (payload) => {
  return {
    type: GET_TIMEOUT,
    payload,
  };
};

export const getTimeOutSuccess = (payload) => {
  return {
    type: GET_TIMEOUT_SUCCESS,
    payload,
  };
};
