/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* DASHBOARD */
export const PENDING_ACTION_PAGINATION = 'PENDING_ACTION_PAGINATION';
export const GET_PENDING_LIST = 'GET_PENDING_LIST';
export const GET_PENDING_LIST_SUCCESS = 'GET_PENDING_LIST_SUCCESS';

/* USER MANAGEMENT */
export const PATIENT_PROFILE_BY_ID = 'PATIENT_PROFILE_BY_ID';
export const PATIENT_PROFILE_BY_ID_SUCCESS = 'PATIENT_PROFILE_BY_ID_SUCCESS';
export const PATIENT_LIST_PAGINATION = 'PATIENT_LIST_PAGINATION';
export const DOCTOR_APPOINTMENT_LIST_PAGINATION =
  'DOCTOR_APPOINTMENT_LIST_PAGINATION';
export const DOCTOR_LIST_PAGINATION = 'DOCTOR_LIST_PAGINATION';
export const DOCTOR_STAFF_ID = 'DOCTOR_STAFF_ID';
export const PHARMACIST_STAFF_ID = 'PHARMACIST_STAFF_ID';
export const GET_ALL_PATIENT_LIST = 'GET_ALL_PATIENT_LIST';
export const GET_ALL_PATIENT_LIST_SUCCESS = 'GET_ALL_PATIENT_LIST_SUCCESS';
export const LINKED_LIST_PAGINATION = 'LINKED_LIST_PAGINATION';
export const GET_LINKED_ACCOUNT_LIST = 'GET_LINKED_ACCOUNT_LIST';
export const GET_LINKED_ACCOUNT_LIST_SUCCESS =
  'GET_LINKED_ACCOUNT_LIST_SUCCESS';
export const MINOR_LIST_PAGINATION = 'MINOR_LIST_PAGINATION';
export const GET_MINOR_ACCOUNT_LIST = 'GET_MINOR_ACCOUNT_LIST';
export const GET_MINOR_ACCOUNT_LIST_SUCCESS = 'GET_MINOR_ACCOUNT_LIST_SUCCESS';
export const GET_MINOR_ACCOUNT_DETAILS = 'GET_MINOR_ACCOUNT_DETAILS';
export const GET_MINOR_ACCOUNT_DETAILS_SUCCESS =
  'GET_MINOR_ACCOUNT_DETAILS_SUCCESS';

export const GET_ALL_DOCTOR_APPOINTMENTS = 'GET_ALL_DOCTOR_APPOINTMENTS';
export const GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS =
  'GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS';

/* USER MANAGEMENT-Staff Profile */
export const GET_ALL_STAFF_PATIENT_APPOINTMENTS =
  'GET_ALL_STAFF_PATIENT_APPOINTMENTS';
export const GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS =
  'GET_ALL_STAFF_PATIENT_APPOINTMENTS_SUCCESS';
export const STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION =
  'STAFF_PATIENT_APPOINTMENTS_LIST_PAGINATION';

/* USER MANAGEMENT-Doctor Profile */
export const GET_ALL_DELEGATES = 'GET_ALL_DELEGATES';
export const GET_ALL_PATIENT_APPOINTMENTS = 'GET_ALL_PATIENT_APPOINTMENTS';
export const GET_ALL_PATIENT_APPOINTMENTS_SUCCESS =
  'GET_ALL_PATIENT_APPOINTMENTS_SUCCESS';
export const GET_ALL_DOCTOR_AND_STAFF = 'GET_ALL_DOCTOR_AND_STAFF';
export const GET_ALL_DOCTOR_AND_STAFF_SUCCESS =
  'GET_ALL_DOCTOR_AND_STAFF_SUCCESS';
export const GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID =
  'GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID';

export const GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS =
  'GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS';

/* USER MANAGEMENT-Laboratory */
export const GET_ALL_LAB_USER = 'GET_ALL_LAB_USER';
export const GET_ALL_LAB_USER_SUCCESS = 'GET_ALL_LAB_USER_SUCCESS';
export const LAB_USER_LIST_PAGINATION = 'LAB_USER_LIST_PAGINATION';
export const GET_ALL_LABORATORIES = 'GET_ALL_LABORATORIES';
export const GET_ALL_LABORATORIES_SUCCESS = 'GET_ALL_LABORATORIES_SUCCESS';
export const LABORATORIES_LIST_PAGINATION = 'LABORATORIES_LIST_PAGINATION';
export const SET_ADD_LAB_DETAILS = 'SET_ADD_LAB_DETAILS';
export const ADD_LAB_USER = 'ADD_LAB_USER';
export const ADD_LAB_USER_SUCCESS = 'ADD_LAB_USER_SUCCESS';
export const GET_LAB_USER_PROFILE_DETAILS_BY_ID =
  'GET_LAB_USER_PROFILE_DETAILS_BY_ID';
export const GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS =
  'GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS';
export const SET_VERIFY_LABORATORY = 'SET_VERIFY_LABORATORY';
export const SET_DECLINE_LABORATORY = 'SET_DECLINE_LABORATORY';
export const SET_VERIFY_DECLINE_LABORATORY_SUCCESS =
  'SET_VERIFY_DECLINE_LABORATORY_SUCCESS';
export const GET_LAB_ORDERS = 'GET_LAB_ORDERS';
export const GET_LAB_ORDERS_SUCCESS = 'GET_LAB_ORDERS_SUCCESS';
export const LAB_ORDERS_LIST_PAGINATION = 'LAB_ORDERS_LIST_PAGINATION';
export const LAB_NO_OF_USERS_LIST_PAGINATION =
  'LAB_NO_OF_USERS_LIST_PAGINATION';
export const GET_LAB_ORDER_DETAILS = 'GET_LAB_ORDER_DETAILS';
export const GET_LAB_ORDER_DETAILS_SUCCESS = 'GET_LAB_ORDER_DETAILS_SUCCESS';
export const SET_LABORATORIES_DETAILS = 'SET_LABORATORIES_DETAILS';
export const UPDATE_REPORT_HOME_COLLECTION = 'UPDATE_REPORT_HOME_COLLECTION';
export const UPDATE_REPORT_HOME_COLLECTION_SUCCESS =
  'UPDATE_REPORT_HOME_COLLECTION_SUCCESS';
export const GET_LAB_NO_OF_USERS = 'GET_LAB_NO_OF_USERS';
export const GET_LAB_NO_OF_USERS_SUCCESS = 'GET_LAB_NO_OF_USERS_SUCCESS';
export const GET_ALL_LAB_BY_WORKPLACE = 'GET_ALL_LAB_BY_WORKPLACE';
export const GET_ALL_LAB_BY_WORKPLACE_SUCCESS =
  'GET_ALL_LAB_BY_WORKPLACE_SUCCESS';
export const GET_ALL_FRANCHISE = 'GET_ALL_FRANCHISE';
export const GET_ALL_FRANCHISE_SUCCESS = 'GET_ALL_FRANCHISE_SUCCESS';
export const GET_LAB_TEST_DATA = 'GET_LAB_TEST_DATA';
export const GET_LAB_TEST_DATA_SUCCESS = 'GET_LAB_TEST_DATA_SUCCESS';
export const SET_MATCHED_DATA = 'SET_MATCHED_DATA';
export const GET_EXISTING_LAB_TEST_DATA = 'GET_EXISTING_LAB_TEST_DATA';
export const GET_EXISTING_LAB_TEST_DATA_SUCCESS =
  'GET_EXISTING_LAB_TEST_DATA_SUCCESS';
export const ADD_LABORATORY = 'ADD_LABORATORY';
export const ADD_LABORATORY_SUCCESS = 'ADD_LABORATORY_SUCCESS';
export const GET_LABORATORY_DETAILS = 'GET_LABORATORY_DETAILS';
export const GET_LABORATORY_DETAILS_SUCCESS = 'GET_LABORATORY_DETAILS_SUCCESS';


/* USER MANAGEMENT-Pharmacist Profile */
export const GET_ALL_PHARMACIST = 'GET_ALL_PHARMACIST';
export const PHARMACIST_USER_LIST_PAGINATION =
  'PHARMACIST_USER_LIST_PAGINATION';
export const PHARMACY_ORDER_LIST_PAGINATION = 'PHARMACY_ORDER_LIST_PAGINATION';
export const PHARMACIES_LIST_PAGINATION = 'PHARMACIES_LIST_PAGINATION';
export const GET_ALL_PHARMACIST_SUCCESS = 'GET_ALL_PHARMACIST_SUCCESS';
export const GET_ALL_PHARMACIES = 'GET_ALL_PHARMACIES';
export const GET_ALL_PHARMACIES_SUCCESS = 'GET_ALL_PHARMACIES_SUCCESS';
export const GET_PHARMACIES_DETAILS = 'GET_PHARMACIES_DETAILS';
export const GET_PHARMACIES_DETAILS_SUCCESS = 'GET_PHARMACIES_DETAILS_SUCCESS';
export const SET_PHARMACIES_DISCOUNT_DETAILS =
  'SET_PHARMACIES_DISCOUNT_DETAILS';
export const SET_ADD_USER_PHARMACIES_DETAILS =
  'SET_ADD_USER_PHARMACIES_DETAILS';
export const ADD_PHARMACIEST_USER = 'ADD_PHARMACIEST_USER';
export const ADD_PHARMACIEST_USER_SUCCESS = 'ADD_PHARMACIEST_USER_SUCCESS';
export const GET_PHARMACIST_PROFILE_DETAILS_BY_ID =
  'GET_PHARMACIST_PROFILE_DETAILS_BY_ID';
export const GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS =
  'GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS';
export const VERIFY_DECLINE_PHARMACY = 'VERIFY_DECLINE_PHARMACY';
export const VERIFY_DECLINE_PHARMACY_SUCCESS =
  'VERIFY_DECLINE_PHARMACY_SUCCESS';
export const UPDATE_DELIVERY_DISCOUNT_INFO = 'UPDATE_DELIVERY_DISCOUNT_INFO';
export const UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS =
  'UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS';
export const GET_PHARMACY_ORDERS = 'GET_PHARMACY_ORDERS';
export const GET_PHARMACY_ORDERS_SUCCESS = 'GET_PHARMACY_ORDERS_SUCCESS';
export const GET_PHARMACY_ORDER_DETAILS = 'GET_PHARMACY_ORDER_DETAILS';
export const GET_PHARMACY_ORDER_DETAILS_SUCCESS =
  'GET_PHARMACY_ORDER_DETAILS_SUCCESS';
export const GET_PHARMACY_NO_OF_USERS = 'GET_PHARMACY_NO_OF_USERS';
export const GET_PHARMACY_NO_OF_USERS_SUCCESS =
  'GET_PHARMACY_NO_OF_USERS_SUCCESS';
export const PHARMACY_NO_OF_USERS_LIST_PAGINATION =
  'PHARMACY_NO_OF_USERS_LIST_PAGINATION';

/* role management */
export const ROLE_LIST_PAGINATION = 'ROLE_LIST_PAGINATION';
export const ROLE_USERS_LIST_PAGINATION = 'ROLE_USERS_LIST_PAGINATION';
export const GET_ROLE_TYPE_LIST = 'GET_ROLE_TYPE_LIST';
export const GET_ROLE_TYPE_LIST_SUCCESS = 'GET_ROLE_TYPE_LIST_SUCCESS';
export const GET_ROLE_NAME_LIST = 'GET_ROLE_NAME_LIST';
export const GET_ROLE_NAME_LIST_SUCCESS = 'GET_ROLE_NAME_LIST_SUCCESS';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const GET_ROLE_USERS = 'GET_ROLE_USERS';
export const GET_ROLE_USERS_SUCCESS = 'GET_ROLE_USERS_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const GET_PERMISSION_DETAILS = 'GET_PERMISSION_DETAILS';
export const GET_PERMISSION_DETAILS_SUCCESS = 'GET_PERMISSION_DETAILS_SUCCESS';
export const UPDATE_PERMISSION_ROLE = 'UPDATE_PERMISSION_ROLE';
export const UPDATE_PERMISSION_ROLE_SUCCESS = 'UPDATE_PERMISSION_ROLE_SUCCESS';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';

/* NOTIFICATION */
export const PATIENT_NOTIFICATION_PAGINATION =
  'PATIENT_NOTIFICATION_PAGINATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_DETAILS = 'GET_NOTIFICATION_DETAILS';
export const GET_NOTIFICATION_DETAILS_SUCCESS =
  'GET_NOTIFICATION_DETAILS_SUCCESS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';

/* APP MANAGEMENT - Addresses */
export const HOSPITAL_ADDRESSES_PAGINATION = 'HOSPITAL_ADDRESSES_PAGINATION';
export const GET_HOSPITAL_ADDRESSES = 'GET_HOSPITAL_ADDRESSES';
export const GET_HOSPITAL_ADDRESSES_SUCCESS = 'GET_HOSPITAL_ADDRESSES_SUCCESS';

/* APP MANAGEMENT - drug */
export const SET_DRUG_PAGINATION = 'SET_DRUG_PAGINATION';
export const GET_DRUG_HISTORY = 'GET_DRUG_HISTORY';
export const GET_DRUG_HISTORY_SUCCESS = 'GET_DRUG_HISTORY_SUCCESS';
export const GET_MERGED_DRUG = 'GET_MERGED_DRUG';
export const GET_MERGED_DRUG_SUCCESS = 'GET_MERGED_DRUG_SUCCESS';
export const SAVE_DRUG = 'SAVE_DRUG';
export const SAVE_DRUG_SUCCESS = 'SAVE_DRUG_SUCCESS';
export const GET_DRUG_DETAILS_BY_ID = 'GET_DRUG_DETAILS_BY_ID';
export const GET_DRUG_DETAILS_BY_ID_SUCCESS = 'GET_DRUG_DETAILS_BY_ID_SUCCESS';
export const SAVE_MERGE_DRUG = 'SAVE_MERGE_DRUG';
export const SAVE_MERGE_DRUG_SUCCESS = 'SAVE_MERGE_DRUG_SUCCESS';
export const GET_PHARMACIES_DRUG = 'GET_PHARMACIES_DRUG';
export const GET_PHARMACIES_DRUG_SUCCESS = 'GET_PHARMACIES_DRUG_SUCCESS';

/* APP MANAGEMENT  */
export const DRUG_INFO_LIST_PAGINATION = 'DRUG_INFO_LIST_PAGINATION';
export const GET_DRUG_INFO_LIST = 'GET_DRUG_INFO_LIST';
export const GET_DRUG_INFO_LIST_SUCCESS = 'GET_DRUG_INFO_LIST_SUCCESS';
export const LIBRARY_LIST_PAGINATION = 'LIBRARY_LIST_PAGINATION';
export const GET_LIBRARY_LIST = 'GET_LIBRARY_LIST';
export const GET_LIBRARY_LIST_SUCCESS = 'GET_LIBRARY_LIST_SUCCESS';
export const AWAITING_APPROVAL_LIBRARY_PAGINATION =
  'AWAITING_APPROVAL_LIBRARY_PAGINATION';
export const GET_AWAITING_APPROVAL_LIBRARY_LIST =
  'GET_AWAITING_APPROVAL_LIBRARY_LIST';
export const GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS =
  'GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS';
export const SET_TIMEOUT = 'SET_TIMEOUT';
export const SET_TIMEOUT_SUCCESS = 'SET_TIMEOUT_SUCCESS';
export const SAVE_TESTS = 'SAVE_TESTS';
export const SAVE_TESTS_SUCCESS = 'SAVE_TESTS_SUCCESS';
export const SAVE_MERGE_ALLERGIES = 'SAVE_MERGE_ALLERGIES';
export const SAVE_MERGE_ALLERGIES_SUCCESS = 'SAVE_MERGE_ALLERGIES_SUCCESS';
export const DELETE_LIBRARY = 'DELETE_LIBRARY';
export const DELETE_LIBRARY_SUCCESS = 'DELETE_LIBRARY_SUCCESS';
export const GET_MERGED_ALLERGY_LIST = 'GET_MERGED_ALLERGY_LIST';
export const GET_MERGED_ALLERGY_LIST_SUCCESS =
  'GET_MERGED_ALLERGY_LIST_SUCCESS';
export const MERGED_ALLERGY_LIST_PAGINATION = 'MERGED_ALLERGY_LIST_PAGINATION';
export const GET_TIMEOUT = 'GET_TIMEOUT';
export const GET_TIMEOUT_SUCCESS = 'GET_TIMEOUT_SUCCESS';

/* APP MANAGEMENT - Specialities */
export const SPECIALITIES_LIST_PAGINATION = 'SPECIALITIES_LIST_PAGINATION';
export const GET_SPECIALITIES_LIST = 'GET_SPECIALITIES_LIST';
export const GET_SPECIALITIES_LIST_SUCCESS = 'GET_SPECIALITIES_LIST_SUCCESS';
export const AWAITING_APPROVAL_PAGINATION = 'AWAITING_APPROVAL_PAGINATION';
export const GET_AWAITING_APPROVAL_LIST = 'GET_AWAITING_APPROVAL_LIST';
export const GET_AWAITING_APPROVAL_LIST_SUCCESS =
  'GET_AWAITING_APPROVAL_LIST_SUCCESS';
export const HEALTH_CONCERN_PAGINATION = 'HEALTH_CONCERN_PAGINATION';
export const GET_HEALTH_CONCERN_LIST = 'GET_HEALTH_CONCERN_LIST';
export const GET_HEALTH_CONCERN_LIST_SUCCESS =
  'GET_HEALTH_CONCERN_LIST_SUCCESS';
export const GET_COMMON_SPECIALITIES_LIST_SUCCESS =
  'GET_COMMON_SPECIALITIES_LIST_SUCCESS';
export const GET_COMMON_SPECIALITIES_LIST = 'GET_COMMON_SPECIALITIES_LIST';
export const SAVE_LIBRARY = 'SAVE_LIBRARY';
export const SAVE_LIBRARY_SUCCESS = 'SAVE_LIBRARY_SUCCESS';
export const GET_COMMON_HEALTH_CONCERN_LIST = 'GET_COMMON_HEALTH_CONCERN_LIST';
export const GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS =
  'GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS';

/* Common */
export const ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE';
export const ACCOUNT_DEACTIVATE = 'ACCOUNT_DEACTIVATE';
export const SET_ACCOUNT_STATUS_RESPONSE = 'SET_ACCOUNT_STATUS_RESPONSE';
export const SET_LOADER = 'SET_LOADER';
export const SET_TABLE_DATA_LOADER = 'SET_TABLE_DATA_LOADER';
export const SET_ORDER_DETAILS_LOADER = 'SET_ORDER_DETAILS_LOADER';
export const SET_SUBMIT_LOADER = 'SET_SUBMIT_LOADER';
export const SET_LIST_LOADER = 'SET_LIST_LOADER';
export const SET_ACCOUNT_ACTIVATE_LOADER = 'SET_ACCOUNT_ACTIVATE_LOADER';
export const SET_ACCOUNT_DEACTIVATE_LOADER = 'SET_ACCOUNT_DEACTIVATE_LOADER';
export const SET_VERIFY_LOADER = 'SET_VERIFY_LOADER';
export const SET_DECLINE_LOADER = 'SET_DECLINE_LOADER';
export const SET_MAP_STATE = 'SET_MAP_STATE';
export const SET_WORKPLACE_DATA = 'SET_WORKPLACE_DATA';
export const REGISTRATION_COUNCIL_DATA = 'REGISTRATION_COUNCIL_DATA';
export const REGISTRATION_COUNCIL_DATA_SUCCESS =
  'REGISTRATION_COUNCIL_DATA_SUCCESS';
export const MEDICAL_CONVENTION_DATA = 'MEDICAL_CONVENTION_DATA';
export const MEDICAL_CONVENTION_DATA_SUCCESS =
  'MEDICAL_CONVENTION_DATA_SUCCESS';
export const SEND_PASSWORD_LINK = 'SEND_PASSWORD_LINK';
export const IS_REDIRECT = 'IS_REDIRECT';
export const UPLOAD_WORKPLACE_DOCUMENT = 'UPLOAD_WORKPLACE_DOCUMENT';
export const GET_WORKPLACE_DOCUMENT = 'GET_WORKPLACE_DOCUMENT';
export const GET_WORKPLACE_DOCUMENT_SUCCESS = 'GET_WORKPLACE_DOCUMENT_SUCCESS';
export const UPLOAD_WORKPLACE_DOCUMENT_SUCCESS =
  'UPLOAD_WORKPLACE_DOCUMENT_SUCCESS';
export const SET_TAB = 'SET_TAB';
export const SET_MASTER_CURRENT_PAGE = 'SET_MASTER_CURRENT_PAGE';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './userManagement/patient/actions';
