import {
  GET_ALL_LAB_USER,
  GET_ALL_LAB_USER_SUCCESS,
  LAB_USER_LIST_PAGINATION,
  LAB_ORDERS_LIST_PAGINATION,
  LAB_NO_OF_USERS_LIST_PAGINATION,
  GET_ALL_LABORATORIES,
  GET_ALL_LABORATORIES_SUCCESS,
  LABORATORIES_LIST_PAGINATION,
  SET_ADD_LAB_DETAILS,
  ADD_LAB_USER,
  GET_LAB_USER_PROFILE_DETAILS_BY_ID,
  GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS,
  SET_VERIFY_LABORATORY,
  SET_DECLINE_LABORATORY,
  SET_VERIFY_DECLINE_LABORATORY_SUCCESS,
  GET_LAB_ORDERS,
  GET_LAB_ORDERS_SUCCESS,
  GET_LAB_ORDER_DETAILS,
  GET_LAB_ORDER_DETAILS_SUCCESS,
  SET_LABORATORIES_DETAILS,
  UPDATE_REPORT_HOME_COLLECTION,
  UPDATE_REPORT_HOME_COLLECTION_SUCCESS,
  GET_LAB_NO_OF_USERS,
  GET_LAB_NO_OF_USERS_SUCCESS,
  GET_ALL_LAB_BY_WORKPLACE,
  GET_ALL_LAB_BY_WORKPLACE_SUCCESS,
  GET_ALL_FRANCHISE,
  GET_ALL_FRANCHISE_SUCCESS,
  GET_LAB_TEST_DATA,
  GET_LAB_TEST_DATA_SUCCESS,
  SET_MATCHED_DATA,
  GET_EXISTING_LAB_TEST_DATA,
  GET_EXISTING_LAB_TEST_DATA_SUCCESS,
  ADD_LABORATORY,
  ADD_LABORATORY_SUCCESS,
  GET_LABORATORY_DETAILS,
  GET_LABORATORY_DETAILS_SUCCESS,
  ADD_LAB_USER_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  laboratoriesPaginationHeader: [],
  labOrdersPaginationHeader: [],
  labUserPaginationHeader: [],
  labUsersPaginationHeader: [],
  laboratoriesList: null,
  labUserList: null,
  addLabDetails: null,
  labUserDetailsById: null,
  labOrdersData: null,
  labOrderData: null,
  laboratoriesData: null,
  reportHomeCollectionDataSuccess: null,
  labUsersList: null,
  allLabListByWorkplace: null,
  allLabFranchiseList: null,
  labTestList: null,
  matchedData: null,
  existingLabTests: [],
  addLabSuccess: null,
  laboratoryDetails: null,
  labUserSuccess: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LAB_USER_LIST_PAGINATION:
      return { ...state, labUserPaginationHeader: action.payload };

    case LAB_ORDERS_LIST_PAGINATION:
      return { ...state, labOrdersPaginationHeader: action.payload };

    case LAB_NO_OF_USERS_LIST_PAGINATION:
      return { ...state, labUsersPaginationHeader: action.payload };

    case GET_ALL_LAB_USER:
      return { ...state, loading: true, error: '' };

    case GET_ALL_LAB_USER_SUCCESS:
      return { ...state, labUserList: action.payload };

    case LABORATORIES_LIST_PAGINATION:
      return { ...state, laboratoriesPaginationHeader: action.payload };

    case GET_ALL_LABORATORIES:
      return { ...state, loading: true, error: '' };

    case GET_ALL_LABORATORIES_SUCCESS:
      return { ...state, laboratoriesList: action.payload };

    case SET_ADD_LAB_DETAILS:
      return { ...state, addLabDetails: action.payload };

    case ADD_LAB_USER:
      return { ...state, loading: true, error: '' };

    case ADD_LAB_USER_SUCCESS:
      return { ...state, labUserSuccess: action.payload };

    case GET_LAB_USER_PROFILE_DETAILS_BY_ID:
      return { ...state, loading: true, error: '' };

    case GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS:
      return { ...state, labUserDetailsById: action.payload };

    case SET_VERIFY_LABORATORY:
      return { ...state, loading: true, error: '' };

    case SET_DECLINE_LABORATORY:
      return { ...state, loading: true, error: '' };

    case SET_VERIFY_DECLINE_LABORATORY_SUCCESS:
      return { ...state, verifyDeclineLaboratoryResponce: action.payload };

    case GET_LAB_ORDERS:
      return { ...state, loading: true, error: '' };

    case GET_LAB_ORDERS_SUCCESS:
      return { ...state, labOrdersData: action.payload };

    case GET_LAB_ORDER_DETAILS_SUCCESS:
      return { ...state, labOrderData: action.payload };

    case GET_LAB_ORDER_DETAILS:
      return { ...state, error: '' };

    case SET_LABORATORIES_DETAILS:
      return { ...state, laboratoriesData: action.payload };

    case UPDATE_REPORT_HOME_COLLECTION:
      return { ...state, error: '' };

    case UPDATE_REPORT_HOME_COLLECTION_SUCCESS:
      return { ...state, reportHomeCollectionDataSuccess: action.payload };

    case GET_LAB_NO_OF_USERS:
      return { ...state, error: '' };

    case GET_LAB_NO_OF_USERS_SUCCESS:
      return { ...state, labUsersList: action.payload };

    case GET_ALL_LAB_BY_WORKPLACE:
      return { ...state, error: '' };

    case GET_ALL_LAB_BY_WORKPLACE_SUCCESS:
      return { ...state, allLabListByWorkplace: action.payload };

    case GET_ALL_FRANCHISE:
      return { ...state, error: '' };

    case GET_ALL_FRANCHISE_SUCCESS:
      return { ...state, allLabFranchiseList: action.payload };

    case GET_LAB_TEST_DATA:
      return { ...state, error: '' };

    case GET_LAB_TEST_DATA_SUCCESS:
      return { ...state, labTestList: action.payload };

    case SET_MATCHED_DATA:
      return { ...state, matchedData: action.payload };

    case GET_EXISTING_LAB_TEST_DATA:
      return { ...state, error: '' };

    case GET_EXISTING_LAB_TEST_DATA_SUCCESS:
      return { ...state, existingLabTests: action.payload };

    case ADD_LABORATORY:
      return { ...state, error: '' };

    case ADD_LABORATORY_SUCCESS:
      return { ...state, addLabSuccess: action.payload };

    case GET_LABORATORY_DETAILS:
      return { ...state, error: '' };

    case GET_LABORATORY_DETAILS_SUCCESS:
      return { ...state, laboratoryDetails: action.payload };

    default:
      return { ...state };
  }
};
