import {
  ROLE_LIST_PAGINATION,
  ROLE_USERS_LIST_PAGINATION,
  GET_ROLE_TYPE_LIST,
  GET_ROLE_TYPE_LIST_SUCCESS,
  GET_ROLE_NAME_LIST,
  GET_ROLE_NAME_LIST_SUCCESS,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  GET_ROLE_USERS,
  GET_ROLE_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  SET_USER_DETAILS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  GET_PERMISSION_DETAILS,
  GET_PERMISSION_DETAILS_SUCCESS,
  UPDATE_PERMISSION_ROLE,
  UPDATE_PERMISSION_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_SUCCESS,

} from '../actions';

export const setRoleTypePagination = (payload) => {
  return {
    type: ROLE_LIST_PAGINATION,
    payload,
  };
};

export const setRoleUsersPagination = (payload) => {
  return {
    type: ROLE_USERS_LIST_PAGINATION,
    payload,
  };
};

export const getRoleTypeList = (payload) => {
  return {
    type: GET_ROLE_TYPE_LIST,
    payload,
  };
};

export const getRoleTypeListSuccess = (payload) => {
  return {
    type: GET_ROLE_TYPE_LIST_SUCCESS,
    payload,
  };
};

export const getRoleNameList = (payload) => {
  return {
    type: GET_ROLE_NAME_LIST,
    payload,
  };
};

export const getRoleNameListSuccess = (payload) => {
  return {
    type: GET_ROLE_NAME_LIST_SUCCESS,
    payload,
  };
};

export const addRole = (payload) => {
  return {
    type: ADD_ROLE,
    payload,
  };
};

export const addRoleSuccess = (payload) => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
};

export const getRoleUsersList = (payload) => {
  return {
    type: GET_ROLE_USERS,
    payload,
  };
};

export const getRoleUsersListSuccess = (payload) => {
  return {
    type: GET_ROLE_USERS_SUCCESS,
    payload,
  };
};

export const addUser = (payload) => {
  return {
    type: ADD_USER,
    payload,
  };
};

export const addUserSuccess = (payload) => {
  return {
    type: ADD_USER_SUCCESS,
    payload,
  };
};

export const setUserDetails = (payload) => {
  return {
    type: SET_USER_DETAILS,
    payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload,
  };
};

export const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  };
};

export const getPermissionDetails = (payload) => {
  return {
    type: GET_PERMISSION_DETAILS,
    payload,
  };
};

export const getPermissionDetailsSuccess = (payload) => {
  return {
    type: GET_PERMISSION_DETAILS_SUCCESS,
    payload,
  };
};

export const updatePermissionRole = (payload) => {
  return {
    type: UPDATE_PERMISSION_ROLE,
    payload,
  };
};

export const updatePermissionRoleSuccess = (payload) => {
  return {
    type: UPDATE_PERMISSION_ROLE_SUCCESS,
    payload,
  };
};

export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    payload,
  };
};

export const deleteRoleSuccess = (payload) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload,
  };
};

export const deleteAdminUser = (payload) => {
  return {
    type: DELETE_ADMIN_USER,
    payload,
  };
};

export const deleteAdminUserSuccess = (payload) => {
  return {
    type: DELETE_ADMIN_USER_SUCCESS,
    payload,
  };
};
