export const permissionData = [
  {
    permission_id: 0,
    permission_name: 'dashboard',
    checked: true,
    label: 'User Management',
    subArr: [],
  },
  {
    permission_id: 1,
    permission_name: 'user_management',
    checked: false,
    label: 'User Management',
    subArr: [
      {
        permission_id: 2,
        permission_name: 'user_patient',
        checked: false,
        label: 'Patient',
      },
      {
        permission_id: 3,
        permission_name: 'user_doctor',
        checked: false,
        label: 'Doctor, Support Staff',
      },
      {
        permission_id: 4,
        permission_name: 'user_pharmacist',
        checked: false,
        label: 'Pharmacists',
      },
      {
        permission_id: 5,
        permission_name: 'user_lab',
        checked: false,
        label: 'Laboratory Employees',
      },
    ],
  },
  {
    permission_id: 6,
    permission_name: 'app_management',
    checked: false,
    label: 'App Management',
    subArr: [
      {
        permission_id: 7,
        permission_name: 'app_addresses',
        checked: false,
        label: 'Addresses',
      },
      {
        permission_id: 8,
        permission_name: 'app_drug',
        checked: false,
        label: 'Drug Information',
      },
      {
        permission_id: 9,
        permission_name: 'app_allergies',
        checked: false,
        label: 'Allergies',
      },
      {
        permission_id: 10,
        permission_name: 'app_specialities',
        checked: false,
        label: 'Specialities',
      },
      {
        permission_id: 11,
        permission_name: 'app_lab_test',
        checked: false,
        label: 'Lab Tests',
      },
      {
        permission_id: 12,
        permission_name: 'app_medical_convention',
        checked: false,
        label: 'Medical Conventions',
      },
    ],
  },
  {
    permission_id: 13,
    permission_name: 'notification_management',
    checked: false,
    label: 'Notification Management',
    subArr: [
      {
        permission_id: 14,
        permission_name: 'notification_patient',
        checked: false,
        label: 'Patient',
      },
      {
        permission_id: 15,
        permission_name: 'notification_doctor',
        checked: false,
        label: 'Doctor, Support Staff',
      },
      {
        permission_id: 16,
        permission_name: 'notification_pharmacist',
        checked: false,
        label: 'Pharmacy Admin, Employees',
      },
      {
        permission_id: 17,
        permission_name: 'notification_lab',
        checked: false,
        label: 'Laboratory Admin, Employees',
      },
    ],
  },
  {
    permission_id: 18,
    permission_name: 'role_management',
    checked: false,
    label: 'Role Management',
    subArr: [
      {
        permission_id: 19,
        permission_name: 'role_add',
        checked: false,
        label: 'Add Roles',
      },
      {
        permission_id: 20,
        permission_name: 'role_edit',
        checked: false,
        label: 'Edit Roles',
      },
      {
        permission_id: 21,
        permission_name: 'role_delete',
        checked: false,
        label: 'Delete Roles',
      },
      {
        permission_id: 22,
        permission_name: 'role_manage_admin',
        checked: false,
        label: 'Manage Admins',
      },
    ],
  },
];

export const permis = [];
