import {
  HOSPITAL_ADDRESSES_PAGINATION,
  GET_HOSPITAL_ADDRESSES,
  GET_HOSPITAL_ADDRESSES_SUCCESS,
} from '../../actions';

export const setHospitalAddressesPagination = (payload) => {
  return {
    type: HOSPITAL_ADDRESSES_PAGINATION,
    payload,
  };
};

export const getHospitalAddresses = (payload) => {
  return {
    type: GET_HOSPITAL_ADDRESSES,
    payload,
  };
};

export const getHospitalAddressesSuccess = (payload) => {
  return {
    type: GET_HOSPITAL_ADDRESSES_SUCCESS,
    payload,
  };
};
