import {
  GET_ALL_PHARMACIST,
  GET_ALL_PHARMACIST_SUCCESS,
  PHARMACIST_USER_LIST_PAGINATION,
  PHARMACY_ORDER_LIST_PAGINATION,
  PHARMACY_NO_OF_USERS_LIST_PAGINATION,
  PHARMACIES_LIST_PAGINATION,
  GET_ALL_PHARMACIES,
  GET_ALL_PHARMACIES_SUCCESS,
  SET_PHARMACIES_DISCOUNT_DETAILS,
  SET_ADD_USER_PHARMACIES_DETAILS,
  ADD_PHARMACIEST_USER,
  GET_PHARMACIST_PROFILE_DETAILS_BY_ID,
  GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS,
  VERIFY_DECLINE_PHARMACY,
  VERIFY_DECLINE_PHARMACY_SUCCESS,
  UPDATE_DELIVERY_DISCOUNT_INFO,
  UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS,
  GET_PHARMACY_ORDERS,
  GET_PHARMACY_ORDERS_SUCCESS,
  GET_PHARMACY_ORDER_DETAILS,
  GET_PHARMACY_ORDER_DETAILS_SUCCESS,
  GET_PHARMACY_NO_OF_USERS,
  GET_PHARMACY_NO_OF_USERS_SUCCESS,
  GET_PHARMACIES_DETAILS,
  GET_PHARMACIES_DETAILS_SUCCESS,
  ADD_PHARMACIEST_USER_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  pharmacistUserPaginationHeader: [],
  pharmacyOrderPaginationHeader: [],
  pharmaciesPaginationHeader: [],
  pharmacyNoOfUsersPaginationHeader: [],
  pharmacistList: null,
  pharmacies: null,
  pharmaciesDiscountDetails: null,
  addUSerPharmaciesDetails: null,
  pharmacistDetailsById: null,
  verifyDeclinePharmacyResponce: null,
  deliveryDiscountSuccess: null,
  pharmacyOrderData: null,
  pharmacyOrderDetailsData: null,
  pharmacyNoOFUsersList: null,
  pharmacyDetails: null,
  pharmacistUserSuccess: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PHARMACIST_USER_LIST_PAGINATION:
      return { ...state, pharmacistUserPaginationHeader: action.payload };

    case PHARMACY_ORDER_LIST_PAGINATION:
      return { ...state, pharmacyOrderPaginationHeader: action.payload };

    case PHARMACIES_LIST_PAGINATION:
      return { ...state, pharmaciesPaginationHeader: action.payload };

    case PHARMACY_NO_OF_USERS_LIST_PAGINATION:
      return { ...state, pharmacyNoOfUsersPaginationHeader: action.payload };

    case GET_ALL_PHARMACIST_SUCCESS:
      return { ...state, pharmacistList: action.payload };

    case GET_ALL_PHARMACIST:
      return { ...state, loading: true, error: '' };

    case GET_ALL_PHARMACIES_SUCCESS:
      return { ...state, pharmacies: action.payload };

    case GET_ALL_PHARMACIES:
      return { ...state, loading: true, error: '' };

    case SET_PHARMACIES_DISCOUNT_DETAILS:
      return { ...state, pharmaciesDiscountDetails: action.payload };

    case SET_ADD_USER_PHARMACIES_DETAILS:
      return { ...state, addUSerPharmaciesDetails: action.payload };

    case ADD_PHARMACIEST_USER:
      return { ...state, loading: true, error: '' };

      case ADD_PHARMACIEST_USER_SUCCESS:
      return { ...state, pharmacistUserSuccess: action.payload };

    case GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS:
      return { ...state, pharmacistDetailsById: action.payload };

    case GET_PHARMACIST_PROFILE_DETAILS_BY_ID:
      return { ...state, loading: true, error: '' };

    case VERIFY_DECLINE_PHARMACY_SUCCESS:
      return { ...state, verifyDeclinePharmacyResponce: action.payload };

    case VERIFY_DECLINE_PHARMACY:
      return { ...state, loading: true, error: '' };

    case UPDATE_DELIVERY_DISCOUNT_INFO:
      return { ...state, loading: true, error: '' };

    case UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS:
      return { ...state, deliveryDiscountSuccess: action.payload };

    case GET_PHARMACY_ORDERS:
      return { ...state, loading: true, error: '' };

    case GET_PHARMACY_ORDERS_SUCCESS:
      return { ...state, pharmacyOrderData: action.payload };

    case GET_PHARMACY_ORDER_DETAILS:
      return { ...state, loading: true, error: '' };

    case GET_PHARMACY_ORDER_DETAILS_SUCCESS:
      return { ...state, pharmacyOrderDetailsData: action.payload };

    case GET_PHARMACY_NO_OF_USERS:
      return { ...state, error: '' };

    case GET_PHARMACY_NO_OF_USERS_SUCCESS:
      return { ...state, pharmacyNoOFUsersList: action.payload };

      case GET_PHARMACIES_DETAILS:
      return { ...state, error: '' };

    case GET_PHARMACIES_DETAILS_SUCCESS:
      return { ...state, pharmacyDetails: action.payload };

    default:
      return { ...state };
  }
};
