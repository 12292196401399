import {
  DOCTOR_LIST_PAGINATION,
  DOCTOR_STAFF_ID,
  GET_ALL_PATIENT_APPOINTMENTS,
  GET_ALL_PATIENT_APPOINTMENTS_SUCCESS,
  GET_ALL_DOCTOR_AND_STAFF,
  GET_ALL_DOCTOR_AND_STAFF_SUCCESS,
  GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID,
  GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  doctorPaginationHeader: [],
  doctorStaffId: null,
  doctorPatientAppointments: null,
  doctorStaffList: null,
  doctorStaffDetailsById: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOCTOR_LIST_PAGINATION:
      return { ...state, doctorPaginationHeader: action.payload };

    case DOCTOR_STAFF_ID:
      return { ...state, doctorStaffId: action.payload };

    case GET_ALL_PATIENT_APPOINTMENTS:
      return { ...state, loading: true, error: '' };

    case GET_ALL_PATIENT_APPOINTMENTS_SUCCESS:
      return { ...state, doctorPatientAppointments: action.payload };

    case GET_ALL_DOCTOR_AND_STAFF:
      return { ...state, loading: true, error: '' };

    case GET_ALL_DOCTOR_AND_STAFF_SUCCESS:
      return { ...state, doctorStaffList: action.payload };

    case GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID:
      return { ...state, loading: true, error: '' };

    case GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID_SUCCESS:
      return { ...state, doctorStaffDetailsById: action.payload };

    default:
      return { ...state };
  }
};
