import {
  PATIENT_NOTIFICATION_PAGINATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
} from '../actions';

export const setPatientPagination = (payload) => {
  return {
    type: PATIENT_NOTIFICATION_PAGINATION,
    payload,
  };
};

export const getNotificationList = (payload) => {
  return {
    type: GET_NOTIFICATION,
    payload,
  };
};

export const getNotificationListSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const getNotificationDetails = (payload) => {
  return {
    type: GET_NOTIFICATION_DETAILS,
    payload,
  };
};

export const getNotificationDetailsSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION_DETAILS_SUCCESS,
    payload,
  };
};

export const updateNotification = (payload) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload,
  };
};

export const updateNotificationSuccess = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload,
  };
};
