import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import { alertDialog } from '../../helpers/commonHelper';
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';
import { LOGIN_EP } from '../../constants/apiEndPoints';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import apiClient from '../../helpers/apiClient';

import { adminRoot } from '../../constants/defaultValues';
import { setCurrentUser, getCurrentUser } from '../../helpers/Utils';

export function* watchLoginUser() {
  yield takeLatest(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (data) =>
  await apiClient
    .post(LOGIN_EP, data)
    .then((res) => res)
    .catch((errors) => errors);

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, payload.user);
    if (loginUser.status === 401) {
      alertDialog('error', loginUser.data.message);
    }
    if (loginUser.status === 404) {
      alertDialog('error', loginUser.data.message);
    }
    console.log('loginUser', loginUser);

    if (loginUser.data.success) {
      const item = { token: loginUser.data.data, role: 0 };
      console.log(item);
      setCurrentUser(item);
      const tokenData = getCurrentUser();

      if (tokenData !== undefined && tokenData !== null) {
        yield put(loginUserSuccess(item));
        history.push(adminRoot);
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
