import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import { GET_HOSPITAL_ADDRESSES } from '../../actions';
import { GET_HOSPITAL_ADDRESSES_EP } from '../../../constants/apiEndPoints';

import { getHospitalAddressesSuccess } from './actions';
import { setLoader } from '../../common/actions';

const getHospitalAddressesListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const apiUrl = `${GET_HOSPITAL_ADDRESSES_EP}/${item.payload.roleID}?limit=${item.payload.limit}&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr);
  }
  return data;
};

function* getHospitalAddressesList(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getHospitalAddressesListAsync, payload);
    if (PendingList.data.success) {
      yield put(setLoader(false));
      yield put(getHospitalAddressesSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchHospitalAddresses() {
  yield takeLatest(GET_HOSPITAL_ADDRESSES, getHospitalAddressesList);
}

export default function* rootSaga() {
  yield all([fork(watchHospitalAddresses)]);
}
