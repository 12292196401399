import {
  GET_ALL_LAB_USER,
  GET_ALL_LAB_USER_SUCCESS,
  LAB_USER_LIST_PAGINATION,
  LAB_ORDERS_LIST_PAGINATION,
  LAB_NO_OF_USERS_LIST_PAGINATION,
  GET_ALL_LABORATORIES,
  GET_ALL_LABORATORIES_SUCCESS,
  LABORATORIES_LIST_PAGINATION,
  SET_ADD_LAB_DETAILS,
  ADD_LAB_USER,
  GET_LAB_USER_PROFILE_DETAILS_BY_ID,
  GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS,
  SET_VERIFY_LABORATORY,
  SET_DECLINE_LABORATORY,
  SET_VERIFY_DECLINE_LABORATORY_SUCCESS,
  GET_LAB_ORDERS,
  GET_LAB_ORDERS_SUCCESS,
  GET_LAB_ORDER_DETAILS,
  GET_LAB_ORDER_DETAILS_SUCCESS,
  SET_LABORATORIES_DETAILS,
  UPDATE_REPORT_HOME_COLLECTION,
  UPDATE_REPORT_HOME_COLLECTION_SUCCESS,
  GET_LAB_NO_OF_USERS,
  GET_LAB_NO_OF_USERS_SUCCESS,
  GET_ALL_LAB_BY_WORKPLACE,
  GET_ALL_LAB_BY_WORKPLACE_SUCCESS,
  GET_ALL_FRANCHISE,
  GET_ALL_FRANCHISE_SUCCESS,
  GET_LAB_TEST_DATA,
  GET_LAB_TEST_DATA_SUCCESS,
  SET_MATCHED_DATA,
  GET_EXISTING_LAB_TEST_DATA,
  GET_EXISTING_LAB_TEST_DATA_SUCCESS,
  ADD_LABORATORY,
  ADD_LABORATORY_SUCCESS,
  GET_LABORATORY_DETAILS_SUCCESS,
  GET_LABORATORY_DETAILS,
  ADD_LAB_USER_SUCCESS,
} from '../../actions';

export const setLabUserPagination = (payload) => {
  return {
    type: LAB_USER_LIST_PAGINATION,
    payload,
  };
};

export const setLaboratoriesPagination = (payload) => {
  return {
    type: LABORATORIES_LIST_PAGINATION,
    payload,
  };
};

export const setLabOrdersPagination = (payload) => {
  return {
    type: LAB_ORDERS_LIST_PAGINATION,
    payload,
  };
};

export const setLabNoOfUsersListPagination = (payload) => {
  return {
    type: LAB_NO_OF_USERS_LIST_PAGINATION,
    payload,
  };
};

export const getAllLabUser = (payload) => {
  return {
    type: GET_ALL_LAB_USER,
    payload,
  };
};

export const getAllLabUserSuccess = (payload) => {
  return {
    type: GET_ALL_LAB_USER_SUCCESS,
    payload,
  };
};

export const getAllLaboratories = (payload) => {
  return {
    type: GET_ALL_LABORATORIES,
    payload,
  };
};

export const getAllLaboratoriesSuccess = (payload) => {
  return {
    type: GET_ALL_LABORATORIES_SUCCESS,
    payload,
  };
};

export const setAddLabDetails = (payload) => {
  return {
    type: SET_ADD_LAB_DETAILS,
    payload,
  };
};

export const addLaboratoryUser = (payload) => {
  return {
    type: ADD_LAB_USER,
    payload,
  };
};

export const addLaboratoryUserSuccess = (payload) => {
  return {
    type: ADD_LAB_USER_SUCCESS,
    payload,
  };
};

export const getLabUserDetailsById = (payload) => {
  return {
    type: GET_LAB_USER_PROFILE_DETAILS_BY_ID,
    payload,
  };
};

export const getLabUserDetailsByIdSuccess = (payload) => {
  return {
    type: GET_LAB_USER_PROFILE_DETAILS_BY_ID_SUCCESS,
    payload,
  };
};

export const setVerifyLaboratory = (payload) => {
  return {
    type: SET_VERIFY_LABORATORY,
    payload,
  };
};

export const setDeclineLaboratory = (payload) => {
  return {
    type: SET_DECLINE_LABORATORY,
    payload,
  };
};

export const setVerifyDeclineLaboratorySuccess = (payload) => {
  return {
    type: SET_VERIFY_DECLINE_LABORATORY_SUCCESS,
    payload,
  };
};

export const getLabOrders = (payload) => {
  return {
    type: GET_LAB_ORDERS,
    payload,
  };
};

export const getLabOrdersSuccess = (payload) => {
  return {
    type: GET_LAB_ORDERS_SUCCESS,
    payload,
  };
};

export const getLabOrderDetails = (payload) => {
  return {
    type: GET_LAB_ORDER_DETAILS,
    payload,
  };
};

export const getLabOrderDetailsSuccess = (payload) => {
  return {
    type: GET_LAB_ORDER_DETAILS_SUCCESS,
    payload,
  };
};

export const setLaboratoriesDetails = (payload) => {
  return {
    type: SET_LABORATORIES_DETAILS,
    payload,
  };
};

export const updateHomeReportCollection = (payload) => {
  return {
    type: UPDATE_REPORT_HOME_COLLECTION,
    payload,
  };
};

export const updateHomeReportCollectionSuccess = (payload) => {
  return {
    type: UPDATE_REPORT_HOME_COLLECTION_SUCCESS,
    payload,
  };
};

export const getLabNoOfUsers = (payload) => {
  return {
    type: GET_LAB_NO_OF_USERS,
    payload,
  };
};

export const getLabNoOfUsersSuccess = (payload) => {
  return {
    type: GET_LAB_NO_OF_USERS_SUCCESS,
    payload,
  };
};

export const getAllLabByWorkplace = (payload) => {
  return {
    type: GET_ALL_LAB_BY_WORKPLACE,
    payload,
  };
};

export const getAllLabByWorkplaceSuccess = (payload) => {
  return {
    type: GET_ALL_LAB_BY_WORKPLACE_SUCCESS,
    payload,
  };
};

export const getAllFranchise = (payload) => {
  return {
    type: GET_ALL_FRANCHISE,
    payload,
  };
};

export const getAllFranchiseSuccess = (payload) => {
  return {
    type: GET_ALL_FRANCHISE_SUCCESS,
    payload,
  };
};

export const getLabTest = (payload) => {
  return {
    type: GET_LAB_TEST_DATA,
    payload,
  };
};

export const getLabTestSuccess = (payload) => {
  return {
    type: GET_LAB_TEST_DATA_SUCCESS,
    payload,
  };
};

export const getExistingLabTest = (payload) => {
  return {
    type: GET_EXISTING_LAB_TEST_DATA,
    payload,
  };
};

export const getExistingLabTestSuccess = (payload) => {
  return {
    type: GET_EXISTING_LAB_TEST_DATA_SUCCESS,
    payload,
  };
};

export const setMatchedListData = (payload) => {
  return {
    type: SET_MATCHED_DATA,
    payload,
  };
};

export const addLaboratory = (payload) => {
  return {
    type: ADD_LABORATORY,
    payload,
  };
};

export const addLaboratorySuccess = (payload) => {
  return {
    type: ADD_LABORATORY_SUCCESS,
    payload,
  };
};

export const getLaboratoryDetails = (payload) => {
  return {
    type: GET_LABORATORY_DETAILS,
    payload,
  };
};

export const getLaboratoryDetailsSuccess = (payload) => {
  return {
    type: GET_LABORATORY_DETAILS_SUCCESS,
    payload,
  };
};
