import {
  PENDING_ACTION_PAGINATION,
  GET_PENDING_LIST,
  GET_PENDING_LIST_SUCCESS,
} from '../actions';

export const setPendingActionPagination = (payload) => {
  return {
    type: PENDING_ACTION_PAGINATION,
    payload,
  };
};

export const getPendingList = (payload) => {
  return {
    type: GET_PENDING_LIST,
    payload,
  };
};

export const getPendingListSuccess = (payload) => {
  return {
    type: GET_PENDING_LIST_SUCCESS,
    payload,
  };
};
