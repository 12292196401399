import {
  SET_LOADER,
  SET_TABLE_DATA_LOADER,
  SET_SUBMIT_LOADER,
  SET_ACCOUNT_ACTIVATE_LOADER,
  SET_ACCOUNT_DEACTIVATE_LOADER,
  SET_VERIFY_LOADER,
  SET_DECLINE_LOADER,
  SET_LIST_LOADER,
  ACCOUNT_ACTIVATE,
  ACCOUNT_DEACTIVATE,
  SET_ACCOUNT_STATUS_RESPONSE,
  SET_MAP_STATE,
  SET_WORKPLACE_DATA,
  REGISTRATION_COUNCIL_DATA,
  REGISTRATION_COUNCIL_DATA_SUCCESS,
  MEDICAL_CONVENTION_DATA,
  MEDICAL_CONVENTION_DATA_SUCCESS,
  SEND_PASSWORD_LINK,
  IS_REDIRECT,
  UPLOAD_WORKPLACE_DOCUMENT,
  GET_WORKPLACE_DOCUMENT,
  GET_WORKPLACE_DOCUMENT_SUCCESS,
  UPLOAD_WORKPLACE_DOCUMENT_SUCCESS,
  SET_TAB,
  SET_MASTER_CURRENT_PAGE,
  SET_CURRENT_ROUTE,
  SET_ORDER_DETAILS_LOADER,
} from '../actions';

const INIT_STATE = {
  loading: false,
  listLoading: false,
  submitLoading: false,
  accountActivateLoading: false,
  accountDeactivateLoading: false,
  veifyLoading: false,
  declineLoading: false,
  isRedirect: false,
  state: '',
  search: '',
  address: '',
  latitude: 43.7724535,
  longitude: -79.4481361,
  locality: '',
  city: '',
  pincode: '',
  name: '',
  street_address: '',
  workplaceListData: [],
  registrationCouncilData: [],
  accountActivateStatus: null,
  accountDeactivateStatus: null,
  workplaceDocument: null,
  uploadworkplaceDocumentSuccess: null,
  activeTab: '1',
  masterCurrentPage: 1,
  tableViewloading: false,
  currentRoute: '',
  orderLoading:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADER:
      return { ...state, loading: action.payload };

    case SET_TABLE_DATA_LOADER:
      return { ...state, tableViewloading: action.payload };

    case SET_LIST_LOADER:
      return { ...state, listLoading: action.payload };

    case SET_SUBMIT_LOADER:
      return { ...state, submitLoading: action.payload };

    case SET_ACCOUNT_ACTIVATE_LOADER:
      return { ...state, accountActivateLoading: action.payload };

    case SET_ACCOUNT_DEACTIVATE_LOADER:
      return { ...state, accountDeactivateLoading: action.payload };

    case SET_VERIFY_LOADER:
      return { ...state, veifyLoading: action.payload };

    case SET_DECLINE_LOADER:
      return { ...state, declineLoading: action.payload };
    case SET_ORDER_DETAILS_LOADER:
      return { ...state, orderLoading: action.payload };

    case IS_REDIRECT:
      return { ...state, isRedirect: action.payload };

    case ACCOUNT_ACTIVATE:
      return { ...state, loading: true, error: '' };

    case ACCOUNT_DEACTIVATE:
      return { ...state, loading: true, error: '' };

    case SET_ACCOUNT_STATUS_RESPONSE:
      return { ...state, accountStatusResponse: action.payload };

    case SET_MAP_STATE:
      const { payload } = action;

      switch (true) {
        case payload.name === 'city':
          return {
            ...state,
            city: payload.value,
          };
        case payload.name === 'search':
          return {
            ...state,
            search: payload.value,
          };
        case payload.name === 'zip_code':
          return {
            ...state,
            pincode: payload.value,
          };
        case payload.name === 'latitude':
          return {
            ...state,
            latitude: payload.value,
          };
        case payload.name === 'longitude':
          return {
            ...state,
            longitude: payload.value,
          };
        case payload.name === 'state':
          return {
            ...state,
            state: payload.value,
          };
        case payload.name === 'street_address':
          return {
            ...state,
            street_address: payload.value,
          };
        case payload.name === 'name':
          return {
            ...state,
            name: payload.value,
          };

        default:
          break;
      }
      return state;
    default:
      return { ...state };

    case SET_WORKPLACE_DATA:
      return { ...state, workplaceListData: action.payload };

    case REGISTRATION_COUNCIL_DATA:
      return { ...state, loading: true, error: '' };

    case REGISTRATION_COUNCIL_DATA_SUCCESS:
      return { ...state, registrationCouncilData: action.payload };

    case MEDICAL_CONVENTION_DATA:
      return { ...state, loading: true, error: '' };

    case MEDICAL_CONVENTION_DATA_SUCCESS:
      return { ...state, medicalConventionData: action.payload };

    case UPLOAD_WORKPLACE_DOCUMENT:
      return { ...state, loading: true, error: '' };

    case UPLOAD_WORKPLACE_DOCUMENT_SUCCESS:
      return { ...state, uploadworkplaceDocumentSuccess: action.payload };

    case GET_WORKPLACE_DOCUMENT:
      return { ...state, error: '' };

    case GET_WORKPLACE_DOCUMENT_SUCCESS:
      return { ...state, workplaceDocument: action.payload };

    case SEND_PASSWORD_LINK:
      return { ...state, error: '' };

    case SET_TAB:
      return { ...state, activeTab: action.payload };

    case SET_MASTER_CURRENT_PAGE:
      return { ...state, masterCurrentPage: action.payload };

    case SET_CURRENT_ROUTE:
      return { ...state, currentRoute: action.payload };
  }
};
