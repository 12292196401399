import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../helpers/apiClient';
import { GET_PENDING_LIST } from '../actions';
import { GET_DASHBOARD_EP } from '../../constants/apiEndPoints';

import { getPendingListSuccess } from './actions';
import { setLoader } from '../common/actions';

const getAllPendingListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const statusStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';
  const typeStr =
    item.payload.type !== null ? `&type=${item.payload.type}` : '';

  const apiUrl = `${GET_DASHBOARD_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + statusStr + typeStr}&sort=${
        item.payload.sort
      }&order=${item.payload.order}`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr + statusStr + typeStr);
  }

  return data;
};

function* getAllPendingList(payload) {
  try {
    yield put(setLoader(true));
    const PendingList = yield call(getAllPendingListAsync, payload);
    yield put(setLoader(false));
    if (PendingList.data.success) {
      yield put(getPendingListSuccess(PendingList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchPendingList() {
  yield takeLatest(GET_PENDING_LIST, getAllPendingList);
}

export default function* rootSaga() {
  yield all([fork(watchPendingList)]);
}
