import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import sagas from './sagas';

const persistConfig = {
  key: 'patientData',
  storage,
  blacklist: ['authUser'],
  whitelist: ['patientData'],
};
const persistedReducer = persistReducer(persistConfig, reducers);

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();

// Redux: Store
const configureStore = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, createLogger())
);

// Middleware: Redux Saga
sagaMiddleware.run(sagas);

// Exports
export const persistor = persistStore(configureStore);

export default configureStore;
