import {
  PATIENT_NOTIFICATION_PAGINATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
} from '../actions';

const INIT_STATE = {
  patientNotificationPagination: [],
  notificationList: null,
  notificationDetails: null,
  updateSuccess: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PATIENT_NOTIFICATION_PAGINATION:
      return { ...state, patientNotificationPagination: action.payload };

    case GET_NOTIFICATION:
      return { ...state, loading: true, error: '' };

    case GET_NOTIFICATION_SUCCESS:
      return { ...state, notificationList: action.payload };

    case GET_NOTIFICATION_DETAILS:
      return { ...state, loading: true, error: '' };

    case GET_NOTIFICATION_DETAILS_SUCCESS:
      return { ...state, notificationDetails: action.payload };

    case UPDATE_NOTIFICATION:
      return { ...state, loading: true, error: '' };

    case UPDATE_NOTIFICATION_SUCCESS:
      return { ...state, updateSuccess: action.payload };

    default:
      return { ...state };
  }
};
