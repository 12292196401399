import {
  DRUG_INFO_LIST_PAGINATION,
  GET_DRUG_INFO_LIST,
  GET_DRUG_INFO_LIST_SUCCESS,
  LIBRARY_LIST_PAGINATION,
  GET_LIBRARY_LIST,
  GET_LIBRARY_LIST_SUCCESS,
  AWAITING_APPROVAL_LIBRARY_PAGINATION,
  GET_AWAITING_APPROVAL_LIBRARY_LIST,
  GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS,
  SAVE_LIBRARY,
  SAVE_LIBRARY_SUCCESS,
  GET_COMMON_SPECIALITIES_LIST,
  GET_COMMON_SPECIALITIES_LIST_SUCCESS,
  GET_COMMON_HEALTH_CONCERN_LIST,
  GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS,
  SET_TIMEOUT,
  SET_TIMEOUT_SUCCESS,
  SAVE_TESTS,
  SAVE_TESTS_SUCCESS,
  SAVE_MERGE_ALLERGIES,
  SAVE_MERGE_ALLERGIES_SUCCESS,
  DELETE_LIBRARY,
  DELETE_LIBRARY_SUCCESS,
  GET_MERGED_ALLERGY_LIST,
  GET_MERGED_ALLERGY_LIST_SUCCESS,
  MERGED_ALLERGY_LIST_PAGINATION,
  GET_TIMEOUT,
  GET_TIMEOUT_SUCCESS,
} from '../actions';

const INIT_STATE = {
  drugPagination: [],
  drugInfoList: null,
  libraryPagination: [],
  libraryList: null,
  commonSpecialitiesList: null,
  CommonHealthConcernList: null,
  librarySuccess: null,
  awaitingApprovalLibraryList: null,
  awaitingApprovalLibraryPagination: [],
  timeoutSuccess: null,
  testsSuccess: null,
  allergyMergeSuccess: null,
  deleteLibSuccess: null,
  mergedAllergyPagination: [],
  mergedAllergyList: null,
  timeoutData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRUG_INFO_LIST_PAGINATION:
      return { ...state, drugPagination: action.payload };

    case GET_DRUG_INFO_LIST:
      return { ...state, loading: true, error: '' };

    case GET_DRUG_INFO_LIST_SUCCESS:
      return { ...state, drugInfoList: action.payload };

    case LIBRARY_LIST_PAGINATION:
      return { ...state, libraryPagination: action.payload };

    case GET_LIBRARY_LIST:
      return { ...state, loading: true, error: '' };

    case GET_LIBRARY_LIST_SUCCESS:
      return { ...state, libraryList: action.payload };

    case AWAITING_APPROVAL_LIBRARY_PAGINATION:
      return { ...state, awaitingApprovalLibraryPagination: action.payload };

    case GET_AWAITING_APPROVAL_LIBRARY_LIST:
      return { ...state, loading: true, error: '' };

    case GET_AWAITING_APPROVAL_LIBRARY_LIST_SUCCESS:
      return { ...state, awaitingApprovalLibraryList: action.payload };

    case GET_COMMON_SPECIALITIES_LIST:
      return { ...state, loading: true, error: '' };

    case GET_COMMON_SPECIALITIES_LIST_SUCCESS:
      return { ...state, commonSpecialitiesList: action.payload };

    case GET_COMMON_HEALTH_CONCERN_LIST:
      return { ...state, loading: true, error: '' };

    case GET_COMMON_HEALTH_CONCERN_LIST_SUCCESS:
      return { ...state, CommonHealthConcernList: action.payload };

    case SAVE_LIBRARY:
      return { ...state, loading: true, error: '' };

    case SAVE_LIBRARY_SUCCESS:
      return { ...state, librarySuccess: action.payload };

    case SET_TIMEOUT:
      return { ...state, loading: true, error: '' };

    case SET_TIMEOUT_SUCCESS:
      return { ...state, timeoutSuccess: action.payload };

    case SAVE_TESTS:
      return { ...state, loading: true, error: '' };

    case SAVE_TESTS_SUCCESS:
      return { ...state, testsSuccess: action.payload };

    case SAVE_MERGE_ALLERGIES:
      return { ...state, loading: true, error: '' };

    case SAVE_MERGE_ALLERGIES_SUCCESS:
      return { ...state, allergyMergeSuccess: action.payload };

    case DELETE_LIBRARY:
      return { ...state, loading: true, error: '' };

    case DELETE_LIBRARY_SUCCESS:
      return { ...state, deleteLibSuccess: action.payload };

    case GET_MERGED_ALLERGY_LIST:
      return { ...state, loading: true, error: '' };

    case GET_MERGED_ALLERGY_LIST_SUCCESS:
      return { ...state, mergedAllergyList: action.payload };

    case MERGED_ALLERGY_LIST_PAGINATION:
      return { ...state, mergedAllergyPagination: action.payload };

    case GET_TIMEOUT:
      return { ...state, mergedAllergyList: action.payload };

    case GET_TIMEOUT_SUCCESS:
      return { ...state, timeoutData: action.payload };

    default:
      return { ...state };
  }
};
