/* User */
export const LOGIN_EP = 'admin/login';
export const FORGET_PASSWORD_EP = 'admin/forgotPassword';
export const RESEND_OTP_EP = 'user/resendOTP';
export const CHANGE_PASSWORD_EP = 'admin/changePassword';
export const VERIFY_OTP_EP = '/user/verifyOTP';
export const UPDATE_PASSWORD_EP = '/user/updatePassword';
export const EMAIL_VERIFY_EP = '/admin/verifyEmail';
export const SET_NEW_PASSWORD_EP = '/user/changePassword';

/* Dashboard */
export const GET_DASHBOARD_EP = 'admin/getdashboard?';

/* Patient */
export const ADD_PATIENT_EP = 'patient/addPatient';
export const GET_ALL_PATIENTS_EP = 'patient/getAllPatients?';
export const GET_PATIENT_EP = 'patient/getPatient?id=';
export const GET_LINKED_LIST_EP = '/patient/getLinkedList?user_id=';
export const GET_MINOR_DETAILS_EP = '/patient/getMinorDetail/';
export const GET_MINOR_LIST_EP = '/patient/getMinorList?parent_id=';
export const UPDATE_LINKED_ACCOUNT_EP = '/patient/updateMultipaleLinkDetail';

/* Doctors */
export const DOCTOR_WORKPLACES_EP = 'doctor/workplaces?doctor_id=';
export const GET_ALL_DOCTOR_AND_STAFF_BY_USER_ID_EP =
  'admin/getAllDoctorAndStaff?user_id=';
export const GET_ALL_DOCTOR_AND_STAFF_EP = 'admin/getAllDoctorAndStaff?';
export const VERIFY_PROFESSIONAL_EP = 'admin/verifyProffession';
export const MY_DOCTORS_EP = 'doctorStaff/mydoctors?staff_id=';
export const ADD_DOCTOR_EP = 'admin/addDoctor';
export const GET_ALL_PATIENT_APPOINTMENTS_EP =
  'admin/getAllPatientAppointments?';
export const GET_DOCUMENTS_EP = '/file/getDocuments?';
export const UPLOAD_DOCUMENTS_EP = '/file/uploadDocuments';

/* Pharmacist */
export const GET_ALL_PHARMACIST_USER_EP = 'admin/pharmacyUsers?';
export const GET_ALL_PHARMACIES_EP = 'admin/allPharmacies?';
export const GET_PHARMACY_DETAILS_EP = 'admin/allPharmacies?workplace_id=';
export const GET_ALL_PHARMACIST_AND_STAFF_EP =
  'admin/getAllDoctorAndStaff?limit=15&';
export const ADD_PHARMACY_USER_EP = 'admin/addPharmacyEmployee';
export const PHARMACY_DETAAILS_BY_ID_EP = 'admin/pharmacyUsers?user_id=';
export const ADD_PHARMACY_EP = 'admin/addPharmacy';
export const VERIFY_DECLINE_PHARMACY_EP = 'admin/verifyPharmacy';
export const UPDATE_DELIVERY_INFO_EP = 'admin/pharmacy/updateDeliveryInfo';
export const GET_PHARMACY_ORDERS_EP = 'admin/getPharmacyOrders?';
export const GET_PHARMACY_ORDER_DETAILS_EP = 'admin/pharmacy/viewOrder?';
export const GET_PHARMACY_USERS_EP = 'admin/pharmacyUsers?';
export const GET_PHARMACY_DETAILS_BY_ID_EP =
  'admin/allPharmacies?workplace_id=';
export const UPDATE_PHARMACY_EP = 'admin/pharmacy/updateWorkPlaceDetails';

/* Staff */
export const ADD_STAFF = 'admin/addStaff';

/* Delegated Staff */
export const WORKPLACE_STAFF_LIST_EP =
  'doctor/workplace/staffList?workplace_id=';
export const GET_ALL_DELEGATES_EP = `doctor/getAllDelegates?doctor_id=`;
export const ADD_DELEGATES_EP = 'doctor/addDelegates';

/* Laboratory */
export const GET_ALL_LAB_USER_EP = 'admin/labUsers?';
export const GET_ALL_LABORATORIES_EP = 'admin/getAllLabs?';
export const ADD_LAB_USER_EP = 'admin/addLabEmployee';
export const ADD_LABORATORY_EP = 'admin/addLaboratory';
export const UPDATE_LABORATORY_EP = 'admin/lab/updateWorkPlaceDetails';
export const UPDATE_LAB_TESTS_EP = 'admin/lab/updateLabTest';
export const LAB_USER_DETAILS_BY_ID_EP = 'admin/labUsers?user_id=';
export const VERIFY_LABORATORY_EP = 'admin/verifylab';
export const GET_LAB_ORDERS_EP = 'admin/getLabOrders?';
export const GET_LAB_ORDER_DETAILS_EP = 'admin/lab/viewOrder?';
export const UPDATE_REPORT_HOME_COLLECTION_EP =
  'admin/lab/updateDeliveryAndDiscount';
export const GET_LAB_USERS_EP = 'admin/labUsers?';
export const GET_ALL_LABS_BY_WORKPLACE_EP = 'admin/getAllLAbs?';
export const SAVE_TEST_EP = 'library/tests/save';
export const GET_EXISTING_LAB_TESTS_EP = 'admin/labTests?lab_id=';
export const GET_LAB_DETAILS_BY_ID_EP = 'admin/getAllLabs?workplace_id=';

/* ROLE Management */
export const GET_PERMISSION_LIST_EP = '/admin/getPermissionList?';
export const GET_ROLE_LIST_EP = '/admin/getRoleList';
export const ADD_ROLE_EP = '/admin/savePermission';
export const GET_ROLE_USERS_EP = '/admin/getRoleUsers/';
export const ADD_USER_EP = '/admin/addAdmin';
export const UPDATE_USER_EP = '/admin/updateAdminDetail';
export const GET_PERMISSION_DETAILS_EP = '/admin/getPermissionDetails/';
export const UPDATE_PERMISSION_EP = '/admin/editPermission';
export const DELETE_ROLE_EP = 'admin/deleteRole/';
export const DELETE_ADMIN_USER_EP = 'admin/deleteAdminUser/';

/* NOTIFICATION */
export const GET_PATIENT_NOTIFICATION_EP = 'admin/getNotificationTemplateList?';
export const GET_NOTIFICATION_DETAILS_EP = 'admin/getNotificationDetails/';
export const UPDATE_NOTIFICATION_EP = 'admin/editNotificationDetails';

/* APP Management */
export const GET_HOSPITAL_ADDRESSES_EP = 'admin/address/role_id';
export const GET_SYMPTOM_BY_ID = '/admin/symptoms/';
export const TIMEOUT_EP = '/admin/timeout';
export const DRUG_HISTORY_EP = '/admin/drugHistory';
export const MERGED_DRUG_EP = '/admin/mergedDrug';
export const SAVE_MERGE_DRUG_EP = '/admin/mergeDrug';
export const SAVE_MERGE_ALLERGIES_EP = 'admin/mergeAllergies';
export const DELETE_LIBRARY_EP = '/library/deleteLibrary';
export const SAVE_DRUG_EP = '/admin/addEditDrug';
export const DRUG_ACTIVE_INACTIVE_EP = '/admin/drugActiveInactive';
export const DRUG_INFO_BY_ID_EP = '/admin/drugInfo';
export const SAVE_TESTS_EP = '/library/tests/save';
export const GET_PHARMACY_DRUG_EP = 'admin/pharmacy/pharmacyDrug?pharmacy_id=';
export const UPDATE_PHARMACY_DRUG = '/admin/pharmacy/updatePharmacyDrug';
export const UPDATE_ADDRESS_EP = '/admin/address/edit/role_id/';
export const MERGED_ALLERGIES_EP = '/admin/mergedAllergies';
export const ALLERGIES_ACTIVE_INACTIVE = '/admin/allergiesActiveInactive';
export const GET_DRUG_MANUFACTURER = '/admin/getDrugManufacturer';
export const GET_TIMEOUT_EP = '/admin/timeout?timeout_for=';

/* Common */
export const CHANGE_ACCOUNT_STATUS_EP = 'admin/changeAccountStatus';
export const DOCTOR_WORKPLACE_EP = 'doctor/workplaces';
export const PHARMACIST_WORKPLACE_EP = 'doctor/workplaces';
export const VERIFY_PROFILE_IMAGE_EP = 'admin/verifyProfileImage';
export const GET_LIBRARY_EP = 'library/getLibrary?library_name=';
export const UPLOAD_WORKPLACE_DOCUMENT_EP = 'file/uploadWorkplaceDocument';
export const GET_WORKPLACE_DOCUMENT_EP = 'file/getWorkplaceDocument?';
export const SAVE_LIBRARY_EP = 'library/saveLibrary';
