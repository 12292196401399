/* eslint-disable import/prefer-default-export */
import {
  GET_ALL_PHARMACIST,
  GET_ALL_PHARMACIST_SUCCESS,
  PHARMACIST_USER_LIST_PAGINATION,
  PHARMACY_ORDER_LIST_PAGINATION,
  PHARMACIES_LIST_PAGINATION,
  PHARMACY_NO_OF_USERS_LIST_PAGINATION,
  GET_ALL_PHARMACIES,
  GET_ALL_PHARMACIES_SUCCESS,
  SET_PHARMACIES_DISCOUNT_DETAILS,
  SET_ADD_USER_PHARMACIES_DETAILS,
  ADD_PHARMACIEST_USER,
  GET_PHARMACIST_PROFILE_DETAILS_BY_ID,
  GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS,
  VERIFY_DECLINE_PHARMACY,
  VERIFY_DECLINE_PHARMACY_SUCCESS,
  UPDATE_DELIVERY_DISCOUNT_INFO,
  UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS,
  GET_PHARMACY_ORDERS,
  GET_PHARMACY_ORDERS_SUCCESS,
  GET_PHARMACY_ORDER_DETAILS,
  GET_PHARMACY_ORDER_DETAILS_SUCCESS,
  GET_PHARMACY_NO_OF_USERS,
  GET_PHARMACY_NO_OF_USERS_SUCCESS,
  GET_PHARMACIES_DETAILS,
  GET_PHARMACIES_DETAILS_SUCCESS,
  ADD_PHARMACIEST_USER_SUCCESS,
} from '../../actions';

export const setPharmacistUserListPagination = (payload) => {
  return {
    type: PHARMACIST_USER_LIST_PAGINATION,
    payload,
  };
};

export const setPharmacyOrdersPagination = (payload) => {
  return {
    type: PHARMACY_ORDER_LIST_PAGINATION,
    payload,
  };
};

export const setPharmaciesListPagination = (payload) => {
  return {
    type: PHARMACIES_LIST_PAGINATION,
    payload,
  };
};

export const setAllPharmaciesListPagination = (payload) => {
  return {
    type: PHARMACIES_LIST_PAGINATION,
    payload,
  };
};

export const setPharmacyNoOfUsersListPagination = (payload) => {
  return {
    type: PHARMACY_NO_OF_USERS_LIST_PAGINATION,
    payload,
  };
};

export const getAllPharmacist = (payload) => {
  return {
    type: GET_ALL_PHARMACIST,
    payload,
  };
};

export const getAllPharmacistSuccess = (payload) => {
  return {
    type: GET_ALL_PHARMACIST_SUCCESS,
    payload,
  };
};

export const getAllPharmacies = (payload) => {
  return {
    type: GET_ALL_PHARMACIES,
    payload,
  };
};

export const getAllPharmaciesSuccess = (payload) => {
  return {
    type: GET_ALL_PHARMACIES_SUCCESS,
    payload,
  };
};

export const setPharmaciesDetails = (payload) => {
  return {
    type: SET_PHARMACIES_DISCOUNT_DETAILS,
    payload,
  };
};

export const setAddUserPharmaciesDetails = (payload) => {
  return {
    type: SET_ADD_USER_PHARMACIES_DETAILS,
    payload,
  };
};

export const addPharmacistUser = (payload) => {
  return {
    type: ADD_PHARMACIEST_USER,
    payload,
  };
};

export const addPharmacistUserSuccess = (payload) => {
  return {
    type: ADD_PHARMACIEST_USER_SUCCESS,
    payload,
  };
};

export const getPharmacistDetailsById = (payload) => {
  return {
    type: GET_PHARMACIST_PROFILE_DETAILS_BY_ID,
    payload,
  };
};

export const getPharmacistDetailsByIdSuccess = (payload) => {
  return {
    type: GET_PHARMACIST_PROFILE_DETAILS_BY_ID_SUCCESS,
    payload,
  };
};

export const setVerifyDeclinePharmacy = (payload) => {
  return {
    type: VERIFY_DECLINE_PHARMACY,
    payload,
  };
};

export const setVerifyDeclinePharmacySuccess = (payload) => {
  return {
    type: VERIFY_DECLINE_PHARMACY_SUCCESS,
    payload,
  };
};

export const updateDeliveryDiscountInfo = (payload) => {
  return {
    type: UPDATE_DELIVERY_DISCOUNT_INFO,
    payload,
  };
};

export const updateDeliveryDiscountInfoSuccess = (payload) => {
  return {
    type: UPDATE_DELIVERY_DISCOUNT_INFO_SUCCESS,
    payload,
  };
};

export const getPharmacyOrders = (payload) => {
  return {
    type: GET_PHARMACY_ORDERS,
    payload,
  };
};

export const getPharmacyOrdersSuccess = (payload) => {
  return {
    type: GET_PHARMACY_ORDERS_SUCCESS,
    payload,
  };
};

export const getPharmacyOrderDetails = (payload) => {
  return {
    type: GET_PHARMACY_ORDER_DETAILS,
    payload,
  };
};

export const getPharmacyOrderDetailsSuccess = (payload) => {
  return {
    type: GET_PHARMACY_ORDER_DETAILS_SUCCESS,
    payload,
  };
};

export const getPharmacyNoOfUsers = (payload) => {
  return {
    type: GET_PHARMACY_NO_OF_USERS,
    payload,
  };
};

export const getPharmacyNoOfUsersSuccess = (payload) => {
  return {
    type: GET_PHARMACY_NO_OF_USERS_SUCCESS,
    payload,
  };
};

export const getAllPharmaciesDetails = (payload) => {
  return {
    type: GET_PHARMACIES_DETAILS,
    payload,
  };
};

export const getAllPharmaciesDetailsSuccess = (payload) => {
  return {
    type: GET_PHARMACIES_DETAILS_SUCCESS,
    payload,
  };
};
