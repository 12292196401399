import jwt_decode from 'jwt-decode';
import { NotificationManager } from '../components/common/react-notifications';
import { permissionData } from '../constants/json-data/permissionJson';
import { setPermission, getPermission, getCurrentUser } from './Utils';

const notificationTime = 3000;
/* function for show alerg message */
export const alertDialog = (type, msg) => {
  if (type === 'error') {
    NotificationManager.error(
      msg,
      'Error',
      notificationTime,
      null,
      null,
      'filled'
    );
  } else {
    NotificationManager.primary(
      msg,
      'Success',
      notificationTime,
      null,
      null,
      'filled'
    );
    if (type === 'info') {
      NotificationManager.info(msg);
    }
  }
};
/* function for password Validate */
export const passwordValidate = (password) => {
  if (
    // /[a-z]/.test(password) &&
    // /[0-9]/.test(password) &&
    /^[a-zA-Z0-9` !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]*$/.test(password) &&
    // /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
    password.length >= 8 &&
    password.length <= 40
  ) {
    return 'success';
  }
  return 'error';
};
/* function for mobile Validate */
export const mobileValidate = (mobile) => {
  const regExp = new RegExp('^\\d+$');
  const isValid = regExp.test(mobile);
  if (isValid && mobile.length === 10) {
    return 'success';
  }
  return 'error';
};

/* function for mobile Validate */
export const pinCodeValidate = (pincode) => {
  if (/[0-9]/.test(pincode)) {
    return 'success';
  }
  return 'error';
};

/* function for mobile Validate */
export const searchKeyValidate = (data) => {
  const searchValidation = /^[a-zA-Z0-9 @.+-,]*$/;
  const search = data.trim();
  const isValidSearch = searchValidation.test(search);
  if (isValidSearch === true) {
    return 'success';
  }
  alertDialog('error', 'Please enter valid search value');
  return 'error';
};

/* function for Check Search key */
export const checkInputAlphabet = (inputString) => {
  const regex = /^[A-Za-z0-9@.+]+$/i;
  if (regex.test(inputString)) {
    return true;
  }
  return false;
};

/* function for decode token */

export const decodeToken = (token) => {
  const decoded = jwt_decode(token);
  return decoded;
};

export const setPermissionUser = () => {
  const user = getCurrentUser();
  const permissionDetails = user !== null && user.token.permissions;
  const permission = permissionData.map((e) => {
    e.subArr.map((role) => {
      permissionDetails.map((data) => {
        if (data.permission_id === role.permission_id) {
          role.checked = !role.checked;
          const list = e.subArr.some((operative) => operative.checked);

          if (list) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        }
      });
    });
    return e;
  });

  setPermission(permission);
};

/* function for add zero in time format token */
export const addZeroPrecesionInTimeFormat = (value) => {
  if (value !== null) {
    const time = value.split(':');

    if (!isNaN(time[1])) {
      if (parseInt(time[1]) < 10) {
        return `${time[0]}:${time[1]}`;
      }
      return value;
    }
    return `00:00`;
  }
};

export const setUserManagementPermission = () => {
  const route = {};
  const permission = getPermission();
  if (permission.length > 0) {
    permission[1].subArr.map((item) => {
      if (item.permission_id === 2 && item.checked === true) {
        route.patientRoute = true;
      }
      if (item.permission_id === 3 && item.checked === true) {
        route.doctorStaffRoute = true;
      }
      if (item.permission_id === 4 && item.checked === true) {
        route.pharmacistRoute = true;
      }
      if (item.permission_id === 5 && item.checked === true) {
        route.labRoute = true;
      }
    });
  }
  return route;
};

export const setRoleManagementPermission = () => {
  const route = {};
  const permission = getPermission();
  if (permission.length > 0) {
    permission.map((user) => {
      if (user.permission_name === 'role_management') {
        user.subArr.map((item) => {
          if (item.permission_id === 19 && item.checked === true) {
            route.addRoleRoute = true;
          }
          if (item.permission_id === 20 && item.checked === true) {
            route.editRoleRoute = true;
          }
          if (item.permission_id === 21 && item.checked === true) {
            route.deleteRoleRoute = true;
          }
          if (item.permission_id === 22 && item.checked === true) {
            route.manageadminRoute = true;
          }
        });
      }
    });
  }
  return route;
};

export const setallPermission = () => {
  const route = {};
  const permission = getPermission();
  if (permission !== null && permission.length > 0) {
    permission.map((item) => {
      if (item.permission_name === 'user_management' && item.checked === true) {
        route.userManagementRoute = true;
      }
      if (item.permission_name === 'app_management' && item.checked === true) {
        route.appManagementRoute = true;
      }
      if (item.permission_name === 'role_management' && item.checked === true) {
        route.roleManagementRoute = true;
      }
      if (
        item.permission_name === 'notification_management' &&
        item.checked === true
      ) {
        route.notificationManagementRoute = true;
      }
    });
  }
  return route;
};

export const setNotificationManagementPermission = () => {
  const route = {};
  const permission = getPermission();
  if (permission.length > 0) {
    permission.map((user) => {
      if (user.permission_name === 'notification_management') {
        user.subArr.map((item) => {
          if (
            item.permission_name === 'notification_patient' &&
            item.checked === true
          ) {
            route.patientNotificationRoute = true;
          }
          if (
            item.permission_name === 'notification_doctor' &&
            item.checked === true
          ) {
            route.doctorStaffNotificationRoute = true;
          }
          if (
            item.permission_name === 'notification_pharmacist' &&
            item.checked === true
          ) {
            route.pharmacistNotificationRoute = true;
          }
          if (
            item.permission_name === 'notification_lab' &&
            item.checked === true
          ) {
            route.labNotificationRoute = true;
          }
        });
      }
    });
  }
  return route;
};

export const setAppManagementPermission = () => {
  const route = {};
  const permission = getPermission();
  if (permission.length > 0) {
    permission.map((user) => {
      if (user.permission_name === 'app_management') {
        user.subArr.map((item) => {
          if (
            item.permission_name === 'app_addresses' &&
            item.checked === true
          ) {
            route.addressesRoute = true;
          }
          if (item.permission_name === 'app_drug' && item.checked === true) {
            route.drugRoute = true;
          }
          if (
            item.permission_name === 'app_allergies' &&
            item.checked === true
          ) {
            route.allergiesRoute = true;
          }
          if (
            item.permission_name === 'app_specialities' &&
            item.checked === true
          ) {
            route.specialitiesRoute = true;
          }
          if (
            item.permission_name === 'app_lab_test' &&
            item.checked === true
          ) {
            route.labTestRoute = true;
          }
          if (
            item.permission_name === 'app_medical_convention' &&
            item.checked === true
          ) {
            route.medicalConventionRoute = true;
          }
        });
      }
    });
  }
  return route;
};

export const regexForEnglish = (data) => {
  return data.replace(/[^A-Za-z0-9!@#$%^&*()_+\s]/g, '');
};
