import {
  SPECIALITIES_LIST_PAGINATION,
  GET_SPECIALITIES_LIST,
  GET_SPECIALITIES_LIST_SUCCESS,
  AWAITING_APPROVAL_PAGINATION,
  GET_AWAITING_APPROVAL_LIST,
  GET_AWAITING_APPROVAL_LIST_SUCCESS,
  HEALTH_CONCERN_PAGINATION,
  GET_HEALTH_CONCERN_LIST,
  GET_HEALTH_CONCERN_LIST_SUCCESS,
} from '../../actions';

export const setSpecialitiesPagination = (payload) => {
  return {
    type: SPECIALITIES_LIST_PAGINATION,
    payload,
  };
};

export const getSpecialitiesList = (payload) => {
  return {
    type: GET_SPECIALITIES_LIST,
    payload,
  };
};

export const getSpecialitiesListSuccess = (payload) => {
  return {
    type: GET_SPECIALITIES_LIST_SUCCESS,
    payload,
  };
};

export const setAwaitingApprovalPagination = (payload) => {
  return {
    type: AWAITING_APPROVAL_PAGINATION,
    payload,
  };
};

export const getAwaitingApprovalList = (payload) => {
  return {
    type: GET_AWAITING_APPROVAL_LIST,
    payload,
  };
};

export const getAwaitingApprovalListSuccess = (payload) => {
  return {
    type: GET_AWAITING_APPROVAL_LIST_SUCCESS,
    payload,
  };
};

export const setHealthConcernPagination = (payload) => {
  return {
    type: HEALTH_CONCERN_PAGINATION,
    payload,
  };
};

export const getHealthConcernList = (payload) => {
  return {
    type: GET_HEALTH_CONCERN_LIST,
    payload,
  };
};

export const getHealthConcernListSuccess = (payload) => {
  return {
    type: GET_HEALTH_CONCERN_LIST_SUCCESS,
    payload,
  };
};
