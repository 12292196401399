import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import { GET_ALL_STAFF_PATIENT_APPOINTMENTS } from '../../actions';
import { GET_ALL_PATIENT_APPOINTMENTS_EP } from '../../../constants/apiEndPoints';

import { AllStaffPatientAppointmentSuccess } from './actions';
import { setLoader } from '../../common/actions';

const getAllDoctorAppointmentAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.searchKey !== '' ? `&search=${item.payload.searchKey}` : '';
  const statusStr =
    item.payload.accountStatusFilter !== null
      ? `&status=${item.payload.accountStatusFilter}`
      : '';

  const apiUrl = `${GET_ALL_PATIENT_APPOINTMENTS_EP}doctor_id=${item.payload.doctorId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + statusStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr + statusStr);
  }

  return data;
};

function* getAllDoctorAppointments(payload) {
  try {
    yield put(setLoader(true));
    const patientAppointmentList = yield call(
      getAllDoctorAppointmentAsync,
      payload
    );
    yield put(setLoader(false));
    if (patientAppointmentList.data.success) {
      yield put(
        AllStaffPatientAppointmentSuccess(patientAppointmentList.data.data)
      );
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchDoctorAppointMents() {
  yield takeLatest(
    GET_ALL_STAFF_PATIENT_APPOINTMENTS,
    getAllDoctorAppointments
  );
}

export default function* rootSaga() {
  yield all([fork(watchDoctorAppointMents)]);
}
