const ErrorMessage = {
  /* Validation Message */
  all_field: 'Please check all fields',
  search: 'Please enter value in Search',
  csv_file: 'Please select valid csv file',
  upload_csv: 'Please upload csv file',
  /* Responce Message */
  search_msg: 'Please enter value in Search',
  string_msg: 'Please enter only character & numbers',
  account_act_msg: 'Account has been activated',
  account_deact_msg: 'Account has been deactivated',
  staff_add_msg: 'Support staff has been added successfully',
  doctor_add_msg: 'Doctor has been added successfully',
  patient_add_msg: 'Patient has beed added successfully',
  patient_user_exist: 'Patient already exists with Mobile number',
  picture_verify: 'Profile Picture has been verified',
  picture_decline: 'Profile Picture has been declined',
  add_workplace: 'Please Add workplace first',
  add_delegated_staff: 'Delegated Staff has been added',
  select_staff: 'Please select Support Staff',
  professional_info_verify: 'Professional Information has been verified',
  professional_info_decline: 'Professional Information has been declined',
  password_change: 'Password change successfully',
  pharmacy_user_add_msg: 'Pharmacy User has been added successfully',
  lab_user_add_msg: 'Lab User has been added successfully',
  pharmacy_decline_msg: 'Pharmacy has been Declined successfully',
  pharmacy_verify_msg: 'Pharmacy has been Verified successfully',
  pharmacy_delivery_discount_update_msg:
    'Pharmacy delivery & discount information has been Updated',
  lab_verify_msg: 'Laboratory has been Approved successfully',
  lab_decline_msg: 'Laboratory has been Declined successfully',
  workplace_success_msg: 'Contract infomation has been uploaded successfully',
  add_role_success_msg: 'Role has been added successfully',
  add_user_success_msg: 'User has been added successfully',
  update_user_success_msg: 'User has been update successfully',
  update_role_success_msg: 'Role has been update successfully',
  update_lab_tests: 'Lab tests updated successfully',
  add_lab: 'Lab with tests added successfully',
  add_allergies: 'Allergy has been added successfully',
  app_allergies_approve: 'Allergy has been Approved successfully',
  app_allergies_decline: 'Allergy has been Declined successfully',
  allergy_edit: 'Allergy has been updated successfully',
  add_speciality: 'Speciality has been added successfully',
  edit_speciality: 'Speciality has been updated successfully',
  add_health_concern: 'Health Concern has been added successfully',
  edit_health_concern: 'Health Concern has been updated successfully',
  speciality_approve: 'Speciality has been Approved successfully',
  speciality_decline: 'Speciality has been Declined successfully',
  add_medical_convention: 'Medical Convention has been added successfully',
  edit_medical_convention: 'Medical Convention has been updated successfully',
  add_symptom: 'Symptoms has been added successfully',
  edit_symptom: 'Symptom has been added successfully',
  add_drug: 'Drug has been added successfully',
  merge_drug: 'Drug has been Merged successfully',
  edit_drug: 'Drug has been updated successfully',
  drug_activate_msg: 'Drug has been Activated successfully',
  drug_deactivate_msg: 'Drug has been Deactivated successfully',
  timeout: 'Timeout has been updated successfully',
  add_labTest: 'Lab Tests has been added successfully',
  edit_labTest: 'Lab Tests has been updated successfully',
  library_active: 'Data has been activated successfully',
  library_deactive: 'Data has been inactivated successfully',
  update_drugs: 'Pharmacy Drugs updated successfully',
  update_address: 'Address has been updated successfully',
  notification_edit: ' are mandatory to update notification',
  duplicate_msg: 'Please enter unique value',
  merge_allergy_error: 'Please select allergy to merge',
  merge_drug_error: 'Please select drugs to merge',
  document_validation_msg: 'Please upload contract information before verify',
};

export default ErrorMessage;
