import {
  DOCTOR_APPOINTMENT_LIST_PAGINATION,
  PATIENT_LIST_PAGINATION,
  GET_ALL_DOCTOR_APPOINTMENTS,
  GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS,
  GET_ALL_PATIENT_LIST,
  GET_ALL_PATIENT_LIST_SUCCESS,
  PATIENT_PROFILE_BY_ID,
  PATIENT_PROFILE_BY_ID_SUCCESS,
  LINKED_LIST_PAGINATION,
  GET_LINKED_ACCOUNT_LIST,
  GET_LINKED_ACCOUNT_LIST_SUCCESS,
  MINOR_LIST_PAGINATION,
  GET_MINOR_ACCOUNT_LIST,
  GET_MINOR_ACCOUNT_LIST_SUCCESS,
  GET_MINOR_ACCOUNT_DETAILS,
  GET_MINOR_ACCOUNT_DETAILS_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  patientProfileDetailsById: null,
  patientPaginationHeader: [],
  doctotAppointmenttPaginationHeader: [],
  linkedAccountPagination: [],
  patientDoctorAppointments: null,
  allPatientList: null,
  linkedAccountList: null,
  minorAccountList: null,
  minorAccountPagination: [],
  minorAccountDetails: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PATIENT_PROFILE_BY_ID:
      return { ...state, loading: true, error: '' };

    case PATIENT_PROFILE_BY_ID_SUCCESS:
      return { ...state, patientProfileDetailsById: action.payload };

    case PATIENT_LIST_PAGINATION:
      return { ...state, patientPaginationHeader: action.payload };

    case DOCTOR_APPOINTMENT_LIST_PAGINATION:
      return { ...state, doctotAppointmenttPaginationHeader: action.payload };

    case GET_ALL_DOCTOR_APPOINTMENTS:
      return { ...state, loading: true, error: '' };

    case GET_ALL_DOCTOR_APPOINTMENTS_SUCCESS:
      return { ...state, patientDoctorAppointments: action.payload };

    case GET_ALL_PATIENT_LIST:
      return { ...state, loading: true, error: '' };

    case GET_ALL_PATIENT_LIST_SUCCESS:
      return { ...state, allPatientList: action.payload };

    case LINKED_LIST_PAGINATION:
      return { ...state, linkedAccountPagination: action.payload };

    case GET_LINKED_ACCOUNT_LIST:
      return { ...state, loading: true, error: '' };

    case GET_LINKED_ACCOUNT_LIST_SUCCESS:
      return { ...state, linkedAccountList: action.payload };

    case MINOR_LIST_PAGINATION:
      return { ...state, minorAccountPagination: action.payload };

    case GET_MINOR_ACCOUNT_LIST:
      return { ...state, loading: true, error: '' };

    case GET_MINOR_ACCOUNT_LIST_SUCCESS:
      return { ...state, minorAccountList: action.payload };

    case GET_MINOR_ACCOUNT_DETAILS:
      return { ...state, loading: true, error: '' };

    case GET_MINOR_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, minorAccountDetails: action.payload };

    default:
      return { ...state };
  }
};
