import {
  all,
  call,
  fork,
  put,
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../../helpers/apiClient';
import {
  GET_ALL_DELEGATES,
  GET_ALL_PATIENT_APPOINTMENTS,
  GET_ALL_DOCTOR_AND_STAFF,
  GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID,
} from '../../actions';
import {
  GET_ALL_PATIENT_APPOINTMENTS_EP,
  GET_ALL_DELEGATES_EP,
  GET_ALL_DOCTOR_AND_STAFF_EP,
  GET_ALL_DOCTOR_AND_STAFF_BY_USER_ID_EP,
} from '../../../constants/apiEndPoints';

import {
  AllPatientAppointmentsSuccess,
  getAllDoctorAndStaffSuccess,
  getDoctorStaffDetailsByIdSuccess,
} from './actions';
import { setLoader, setListLoader } from '../../common/actions';

const getDelegatedStaffAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT('GET', GET_ALL_DELEGATES_EP + item.payload);

  return data;
};

const getAllPatientAppointmentAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.searchKey !== '' ? `&search=${item.payload.searchKey}` : '';
  const accountStatusFilterStr =
    item.payload.accountStatusFilter !== null
      ? `&status=${item.payload.accountStatusFilter}`
      : '';

  const apiUrl = `${GET_ALL_PATIENT_APPOINTMENTS_EP}doctor_id=${item.payload.doctorId}&offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + accountStatusFilterStr}&sort=${
        item.payload.sort
      }&order=${item.payload.order}`
    );
  } else {
    data = await AXIOS_AUTH_KIT(
      'GET',
      apiUrl + searchStr + accountStatusFilterStr
    );
  }
  return data;
};

function* getDelegatedStaff(payload) {
  try {
    yield call(getDelegatedStaffAsync, payload);
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getAllPatientAppointments(payload) {
  try {
    yield put(setListLoader(true));
    const patientAppointmentList = yield call(
      getAllPatientAppointmentAsync,
      payload
    );
    yield put(setListLoader(false));
    if (patientAppointmentList.data.success) {
      yield put(
        AllPatientAppointmentsSuccess(patientAppointmentList.data.data)
      );
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

const getAllDoctorStaffListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const statusStr =
    item.payload.status !== null ? `&status=${item.payload.status}` : '';
  const typeStr =
    item.payload.type !== null ? `&type=${item.payload.type}` : '';
  const apiUrl = `${GET_ALL_DOCTOR_AND_STAFF_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = await AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + statusStr + typeStr}&sort=${
        item.payload.sort
      }&order=${item.payload.order}`
    );
  } else {
    data = await AXIOS_AUTH_KIT(
      'GET',
      apiUrl + searchStr + statusStr + typeStr
    );
  }

  return data;
};

function* getAllDoctorStaffList(payload) {
  try {
    yield put(setLoader(true));
    const doctorAndStaffList = yield call(getAllDoctorStaffListAsync, payload);
    if (doctorAndStaffList.data.success) {
      yield put(setLoader(false));
      yield put(getAllDoctorAndStaffSuccess(doctorAndStaffList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

const getAllDoctorStaffDetailsByIdAsync = async (item) => {
  const data = await AXIOS_AUTH_KIT(
    'GET',
    `${
      GET_ALL_DOCTOR_AND_STAFF_BY_USER_ID_EP + item.payload.doctorStaffId
    }&is_staff=${item.payload.is_staff}`
  );
  return data;
};

function* getAllDoctorStaffDetailsById(payload) {
  try {
    yield put(setLoader(true));
    const doctorAndStaffById = yield call(
      getAllDoctorStaffDetailsByIdAsync,
      payload
    );
    yield put(setLoader(false));
    if (doctorAndStaffById.data.success) {
      yield put(getDoctorStaffDetailsByIdSuccess(doctorAndStaffById.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchDelegatedStaff() {
  yield takeLatest(GET_ALL_DELEGATES, getDelegatedStaff);
}

export function* watchPatientAppointMents() {
  yield takeLatest(GET_ALL_PATIENT_APPOINTMENTS, getAllPatientAppointments);
}

export function* watchDoctorStaffList() {
  yield takeLatest(GET_ALL_DOCTOR_AND_STAFF, getAllDoctorStaffList);
}

export function* watchDoctorStaffDetailsByID() {
  yield takeLatest(
    GET_DOCTOR_AND_STAFF_DETAILS_BY_USER_ID,
    getAllDoctorStaffDetailsById
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchDelegatedStaff),
    fork(watchPatientAppointMents),
    fork(watchDoctorStaffList),
    fork(watchDoctorStaffDetailsByID),
  ]);
}
