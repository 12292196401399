import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AXIOS_AUTH_KIT } from '../../helpers/apiClient';
import {
  GET_ROLE_TYPE_LIST,
  GET_ROLE_NAME_LIST,
  ADD_ROLE,
  GET_ROLE_USERS,
  ADD_USER,
  UPDATE_USER,
  GET_PERMISSION_DETAILS,
  UPDATE_PERMISSION_ROLE,
  DELETE_ROLE,
  DELETE_ADMIN_USER,
} from '../actions';
import {
  GET_PERMISSION_LIST_EP,
  GET_ROLE_LIST_EP,
  ADD_ROLE_EP,
  GET_ROLE_USERS_EP,
  ADD_USER_EP,
  UPDATE_USER_EP,
  GET_PERMISSION_DETAILS_EP,
  UPDATE_PERMISSION_EP,
  DELETE_ROLE_EP,
  DELETE_ADMIN_USER_EP,
} from '../../constants/apiEndPoints';

import {
  getRoleTypeListSuccess,
  getRoleNameListSuccess,
  addRoleSuccess,
  getRoleUsersListSuccess,
  addUserSuccess,
  updateUserSuccess,
  getPermissionDetailsSuccess,
  updatePermissionRoleSuccess,
  deleteRoleSuccess,
  deleteAdminUserSuccess,
} from './actions';
import { setLoader, setSubmitLoader } from '../common/actions';
import { alertDialog } from '../../helpers/commonHelper';
import ErrorMessage from '../../constants/errorMessage';

const getRoleListAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';
  const statusStr =
    item.payload.status !== null ? `&role_id=${item.payload.status}` : '';

  const apiUrl = `${GET_PERMISSION_LIST_EP}offset=${item.payload.offset}&limit=${item.payload.limit}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr + statusStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr + statusStr);
  }

  return data;
};

const getRoleNameListAsync = async () => {
  const data = AXIOS_AUTH_KIT('GET', GET_ROLE_LIST_EP);

  return data;
};

const saveRoleAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', ADD_ROLE_EP, item.payload);

  return data;
};

const getRoleUsersAsync = async (item) => {
  let data = null;
  const searchStr =
    item.payload.search !== '' ? `&search=${item.payload.search}` : '';

  const apiUrl = `${GET_ROLE_USERS_EP + item.payload.roleId}?limit=${
    item.payload.limit
  }&offset=${item.payload.offset}`;

  if (item.payload.sort && item.payload.order) {
    data = AXIOS_AUTH_KIT(
      'GET',
      `${apiUrl + searchStr}&sort=${item.payload.sort}&order=${
        item.payload.order
      }`
    );
  } else {
    data = AXIOS_AUTH_KIT('GET', apiUrl + searchStr);
  }

  return data;
};

const addUserAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', ADD_USER_EP, item.payload);
  return data;
};

const updateUserAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', UPDATE_USER_EP, item.payload);
  return data;
};

const getpermissionDetailAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('GET', GET_PERMISSION_DETAILS_EP + item.payload);
  return data;
};

const updateRoleAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('POST', UPDATE_PERMISSION_EP, item.payload);
  return data;
};

const deleteRoleAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('DELETE', DELETE_ROLE_EP + item.payload);
  return data;
};

const setDeleteUserAsync = async (item) => {
  const data = AXIOS_AUTH_KIT('DELETE', DELETE_ADMIN_USER_EP + item.payload);
  return data;
};

function* getRoleList(payload) {
  try {
    yield put(setLoader(true));
    const roleList = yield call(getRoleListAsync, payload);
    yield put(setLoader(false));
    if (roleList.data.success) {
      yield put(getRoleTypeListSuccess(roleList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getRoleNameList(payload) {
  try {
    const roleList = yield call(getRoleNameListAsync, payload);
    if (roleList.data.success) {
      yield put(getRoleNameListSuccess(roleList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* saveRole(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(saveRoleAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(addRoleSuccess(roleList.data));
      alertDialog('success', ErrorMessage.add_role_success_msg);
    } else {
      alertDialog('error', roleList.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getRoleUsers(payload) {
  try {
    yield put(setLoader(true));
    const roleUsers = yield call(getRoleUsersAsync, payload);
    yield put(setLoader(false));
    if (roleUsers.data.success) {
      yield put(getRoleUsersListSuccess(roleUsers.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* addUser(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(addUserAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(addUserSuccess(roleList.data));
      alertDialog('success', ErrorMessage.add_user_success_msg);
    } else if (roleList.data.message) {
      alertDialog('error', roleList.data.message);
    } else {
      alertDialog('error', roleList.data[0].constraints);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* updateUser(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(updateUserAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(updateUserSuccess(roleList.data));
      alertDialog('success', ErrorMessage.update_user_success_msg);
    } else if (roleList.data.message) {
      alertDialog('error', roleList.data.message);
    } else {
      alertDialog('error', roleList.data[0].constraints);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* getpermissionDetail(payload) {
  try {
    yield put(setLoader(true));
    const roleList = yield call(getpermissionDetailAsync, payload);
    yield put(setLoader(false));
    if (roleList.data.success) {
      yield put(getPermissionDetailsSuccess(roleList.data.data));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* updateRole(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(updateRoleAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(updatePermissionRoleSuccess(roleList.data));
      alertDialog('success', ErrorMessage.update_user_success_msg);
    } else {
      alertDialog('error', roleList.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setDeleteRole(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(deleteRoleAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(deleteRoleSuccess(roleList.data));
      alertDialog('success', roleList.data.data.message);
    } else {
      alertDialog('error', roleList.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

function* setDeleteUser(payload) {
  try {
    yield put(setSubmitLoader(true));
    const roleList = yield call(setDeleteUserAsync, payload);
    yield put(setSubmitLoader(false));
    if (roleList.data.success) {
      yield put(deleteAdminUserSuccess(roleList.data));
      alertDialog('success', roleList.data.data.message);
    } else {
      alertDialog('error', roleList.data.message);
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}

export function* watchRoleList() {
  yield takeLatest(GET_ROLE_TYPE_LIST, getRoleList);
}

export function* watchRoleNameList() {
  yield takeLatest(GET_ROLE_NAME_LIST, getRoleNameList);
}

export function* watchAddRole() {
  yield takeLatest(ADD_ROLE, saveRole);
}

export function* watchRoleUsers() {
  yield takeLatest(GET_ROLE_USERS, getRoleUsers);
}

export function* watchAddUser() {
  yield takeLatest(ADD_USER, addUser);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUser);
}

export function* watchgetpermissionDetail() {
  yield takeLatest(GET_PERMISSION_DETAILS, getpermissionDetail);
}

export function* watchgetupdateRole() {
  yield takeLatest(UPDATE_PERMISSION_ROLE, updateRole);
}

export function* watchDeleteRole() {
  yield takeLatest(DELETE_ROLE, setDeleteRole);
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_ADMIN_USER, setDeleteUser);
}

export default function* rootSaga() {
  yield all([fork(watchRoleList)]);
  yield all([fork(watchRoleNameList)]);
  yield all([fork(watchAddRole)]);
  yield all([fork(watchRoleUsers)]);
  yield all([fork(watchAddUser)]);
  yield all([fork(watchUpdateUser)]);
  yield all([fork(watchgetpermissionDetail)]);
  yield all([fork(watchgetupdateRole)]);
  yield all([fork(watchDeleteRole)]);
  yield all([fork(watchDeleteUser)]);
}
