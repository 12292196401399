import axios from 'axios';
import { setCurrentUser, getCurrentUser } from './Utils';

const user = getCurrentUser();
const apiClient = axios.create({
  // baseURL: 'http://180.149.241.208:3044/',
  baseURL: 'https://backend.cryptopill.co.in/',
  timeout: 20000,

  headers: {
    'Content-Type': 'application/json',
    Authorization: user !== null && user.token.token,
  },
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401) {
      if (
        error.response.data.status === false &&
        error.response.data.message === 'jwt expired'
      ) {
        setCurrentUser(null);
        window.location = `/login`;
      }
      return error.response;
    }
    if (error.response.status === 404) {
      return error.response;
    }
    if (error.response.status === 400) {
      return error.response;
    }
    return error.response;
  }
);

export default apiClient;

export const AXIOS_AUTH_KIT = async (
  method: string,
  endPoint: string,
  data
) => {
  const url = process.env.URL || 'https://backend.cryptopill.co.in/';
  const userToken = await getCurrentUser();
  const header = {
    'Content-Type': 'application/json',
    Authorization: userToken !== null && userToken.token.token,
  };
  const auth = axios.create({
    baseURL: url,
    headers: header,
  });

  let res;
  switch (method) {
    case 'POST':
      res = await auth
        .post(endPoint, data, header)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            logout(e.response);
          }
          return e.response;
        });
      break;

    case 'GET':
      res = await auth
        .get(endPoint, header)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            logout(e.response);
          }
          return e.response;
        });
      break;
    case 'PUT':
      res = await auth
        .put(endPoint, data)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          return e.response;
        });
      break;
    case 'DELETE':
      if (data) {
        res = await auth
          .delete(endPoint, data)
          .then((res) => {
            return res;
          })
          .catch((e) => {
            if (e.response.status === 401) {
              logout(e.response);
            }
            return e.response;
          });
      } else {
        res = await auth
          .delete(endPoint, header)
          .then((res) => {
            return res;
          })
          .catch((e) => {
            if (e.response.status === 401) {
              logout(e.response);
            }
            return e.response;
          });
      }
      break;
  }
  return res;
};

const logout = (res) => {
  if (res.status === 401) {
    setCurrentUser(null);
    window.location = `/login`;
  }
};
